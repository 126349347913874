import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  background-color: #003627;
  border-radius: 20px;
  width: min(300px, calc(50vw - 30px));
  height: max-content;
  margin: 10px;

  .quantity-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #ff8327;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-img {
    width: 100%;
    height: min(300px, calc(50vw - 30px));
    border-radius: 20px 20px 0 0;
  }
`;

export const DetailsWrapper = styled.div`
  padding: 5px;

  .title {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
  .description {
    color: #becec9;
    font-size: 12px;
  }
  .price {
    color: #ff8327;
    font-size: 14px;
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 280px) and (max-width: 370px) {
    .title {
      font-size: 12px;
    }
    .description {
      font-size: 10px;
    }
    .price {
      font-size: 10px;
    }
  }
`;
