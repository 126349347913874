import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  min-width: 126px;
  min-height: 100vh;
`;

export const SidebarContainer = styled.div`
  position: fixed;
  width: 126px;
  min-height: 100vh;
  background: #010101;
  padding-top: 31px;
`;

export const Avatar = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 72px;
`;

export const SidebarBtn = styled(Link)`
  display: block;
  width: 100%;
  padding: 17px;
  background: ${(props) => props.bg || 'transparent'};
  text-decoration: none;
  &:hover {
    color: #ffffff;
  }
`;

export const SidebarBtnIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const SidebarBtnText = styled.p`
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
`;
