import React from 'react';
import { main_api } from 'adminPanel/api/auth';
import { numberToWords } from 'number-to-words';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import CsvIcon from '../../assets/images/icons/csv-icon.png';
import FileIcon from '../../assets/images/icons/file-icon.png';
import XlsIcon from '../../assets/images/icons/xls-icon.png';
import { avatarBgColors } from '../../config/user.config';
import {
  ActionButton,
  ActionButtonIcon,
  CustomColumn,
  TableContainer,
  UserAvatar,
  UserNameAvatar,
} from './Table.styled';

const ReportTable = ({ columns, data }) => {
  const downloadFile = async (url, name, type) => {
    await main_api.get(url, { responseType: 'blob' }).then((res) => {
      const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', name + '.' + type);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };

  const nameTemplate = (data) => {
    const randomIndex = Math.floor(Math.random() * avatarBgColors.length);
    const backgroundColor = avatarBgColors[randomIndex];
    const fontColor = randomIndex < 5 ? 'white' : 'black';
    return (
      <CustomColumn className="d-flex align-items-center">
        {data.avatar ? (
          <UserAvatar src={data.avatar} alt="user-avatar" />
        ) : (
          <UserNameAvatar
            className="d-flex align-items-center justify-content-center"
            backgroundColor={data.name ? backgroundColor : '#000'}
            fontColor={data.name ? fontColor : '#fff'}
          >
            {data.name ? data.name[0] : 'N'}
          </UserNameAvatar>
        )}
        {data.name || 'Null'}
      </CustomColumn>
    );
  };

  const ratingTemplate = (data) => {
    return <CustomColumn className="text-capitalize">{+data.rating + ' Star'}</CustomColumn>;
  };
  const gradeTemplate = (data) => {
    return (
      <CustomColumn className="text-capitalize">
        {numberToWords.toWords(+data.student.grade) + ' Grade'}
      </CustomColumn>
    );
  };

  const downloadTemplate = (data) => {
    return (
      <CustomColumn className="d-flex">
        <ActionButton
          width="40px"
          height="40px"
          background="#fff"
          marginRight="8px"
          onClick={() =>
            downloadFile(
              '/bookings/bookings_for_student_excel/?user_id=' + data.id,
              data.name,
              'xls'
            )
          }
        >
          <ActionButtonIcon src={XlsIcon} width="24px" height="24px" />
        </ActionButton>
        <ActionButton
          width="40px"
          height="40px"
          background="#fff"
          marginRight="8px"
          onClick={() =>
            downloadFile('/bookings/bookings_for_student_csv/?user_id=' + data.id, data.name, 'csv')
          }
        >
          <ActionButtonIcon src={CsvIcon} width="24px" height="24px" />
        </ActionButton>
        <ActionButton
          width="40px"
          height="40px"
          background="#fff"
          marginRight="8px"
          onClick={() =>
            downloadFile('/bookings/bookings_for_student_pdf/?user_id=' + data.id, data.name, 'pdf')
          }
        >
          <ActionButtonIcon src={FileIcon} width="24px" height="24px" />
        </ActionButton>
      </CustomColumn>
    );
  };

  return (
    <TableContainer>
      <DataTable
        style={{ border: 'none' }}
        tableStyle={{ border: 'none' }}
        value={data}
        globalFilterFields={columns.map((item) => item.field)}
        emptyMessage="No users found."
        reorderableRows
        responsiveLayout="scroll"
        sortMode="single"
        sortField="name"
        editMode="row"
        sortOrder={1}
      >
        <Column field="name" header="Name" body={nameTemplate} />
        <Column field="rating" header="Rating" body={ratingTemplate} />
        <Column field="grade" header="Grade" body={gradeTemplate} />
        <Column field="download" header="Download" body={downloadTemplate} />
      </DataTable>
    </TableContainer>
  );
};

export default ReportTable;
