import { useState } from 'react';
import styled from 'styled-components';
import close from '../../assets/svgs/close.svg';
import search from '../../assets/svgs/search.svg';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  flex: 1;
  height: 40px;
  background-color: var(--bg-color1);
  border-radius: 10px;
  padding: 0 10px;

  :focus {
    box-shadow: 0 0 0 2px var(--line-color2);
  }
`;

const Suffix = styled.div`
  position: absolute;
  right: 14px;
  display: flex;
  column-gap: 14px;

  .icon {
    display: block;
    width: 18px;
    height: 18px;
    background-color: var(--text-color);
    mask-size: contain;
    mask-repeat: no-repeat;
    cursor: pointer;

    &--search {
      mask-image: url(${search});
    }

    &--close {
      mask-image: url(${close});
    }
  }
`;

const Search = ({ onChange, onSearch, placeholder }) => {
  const [searchText, setSearchText] = useState('');

  const changeHandler = (event) => {
    const { value } = event.target;
    onChange && onChange(value);
    setSearchText(value);
  };

  const onClose = () => {
    setSearchText('');
  };

  const searchHandler = () => {
    onSearch && searchText && onSearch(searchText);
  };

  const onEnter = (event) => {
    if (event.key === 'Enter') {
      searchHandler();
    }
  };

  return (
    <Wrapper>
      <Input
        placeholder={placeholder}
        value={searchText}
        onChange={changeHandler}
        onKeyDown={onEnter}
        enterKeyHint="search"
      />
      <Suffix>
        {searchText && <span onClick={onClose} className="icon icon--close" />}
        <span onClick={searchHandler} className="icon icon--search" />
      </Suffix>
    </Wrapper>
  );
};

export default Search;
