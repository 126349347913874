/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components/macro';

export const LayoutWrapper = styled.div`
  display: flex;
  height: 100vh;

  .title {
    text-align: center;
  }

  .close {
    display: none;
  }

  .bottom_navbar {
    background: #0b0d11;
    width: 400px;

    @media only screen and (min-width: 280px) and (max-width: 600px) {
      position: absolute;
      height: 100vh;
      z-index: -9;
      width: 0;
      transition: all 1s ease;

      ${({ navbarShow }) =>
        navbarShow &&
        css`
          left: 0;
          z-index: 9;
          width: 70%;
          display: inherit;
        `}

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #fff;
        width: 20px;
        height: 20px;
        display: inherit;
      }
    }
  }
  .main_background_div {
    overflow-y: auto;
    background: black;
    width: 100%;

    .hamburger {
      position: absolute;
      top: 20px;
      left: 20px;
      color: #fff;
      width: 25px;
      display: none;

      @media only screen and (min-width: 280px) and (max-width: 600px) {
        display: inherit;
      }
    }
  }
`;
