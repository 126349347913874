import React from 'react';
import { Dropdown } from 'react-bootstrap';
import ArrowDownIcon from '../../assets/images/icons/arrow-down-icon.svg';
import { DropdownButton, DropdownIcon, DropdownItem, DropdownMenu } from './Dropdown.styled';

const AddNewUserDropdown = ({ dropdownItems, activeDropdownItem, setActiveDropdownItem }) => {
  return (
    <Dropdown>
      <DropdownButton className="d-flex align-items-center justify-content-between">
        {activeDropdownItem ? activeDropdownItem : 'Select Role'}
        <DropdownIcon src={ArrowDownIcon} alt="arrow-down-icon" />
      </DropdownButton>
      <DropdownMenu>
        {dropdownItems.map((item, index) => {
          return (
            <DropdownItem key={index} onClick={() => setActiveDropdownItem(item)}>
              {item}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default AddNewUserDropdown;
