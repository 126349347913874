import styled from 'styled-components';

export const UploadFileDragWrapper = styled.div`
  padding: 30px 60px;
  width: 100%;
  height: 202px;
  background: #ededed;
  border: 1px dashed #000000;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
`;

export const UploadFileInput = styled.input``;

export const UploadFileIcon = styled.img`
  width: 68.78px;
  margin-bottom: 25.5px;
`;

export const UploadFileTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 18px;
`;

export const UploadFileBrowseLabel = styled.label`
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;

export const UploadFileBrowseInput = styled.input.attrs({
  type: 'file',
  accept: ['video/*', 'image/*', 'text/*'],
})`
  display: none;
`;

export const UploadFileFormates = styled.p`
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`;
