const prototype = String.prototype;

/* ============================================ */
//  capitalize
/* ============================================ */
prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

/* ============================================ */
//  short text
/* ============================================ */
prototype.short = function (max = 10) {
  if (this.length <= max) return this;

  const n = Math.floor((max - 3) / 2);
  return `${this.slice(0, n)}****${this.slice(-n)}`;
};
