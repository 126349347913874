/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const Wrapper = styled.div`
  width: 470px;
  color: #fff;
  background: rgba(11, 13, 17, 1);
  padding: 4rem 2rem;
  border: 1px solid rgba(16, 210, 250, 1);
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Body = styled.div`
  padding: 20px;
  text-align: center;
  font-family: 'Montserrat';
  .title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 1.5rem;
  }

  .description {
    font-size: 14px;
  }

  .title + .description {
    margin-top: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Orbitron';
  color: white;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
  }
  .ok {
    background: ${COLORS.theme_gradient};
    width: 100%;
    padding: 0.5rem;
  }

  .deleteButton {
    background: ${COLORS.theme_gradient_delete};
    width: 100%;
    padding: 0.5rem;
  }
  .close {
    padding-top: 0.5rem;
  }
`;
