import React from 'react';
import FilterIcon from '../../assets/images/icons/filter-icon.svg';
import { BtnIcon, FilterBtn } from './Button.styled';

const FilterButton = () => {
  return (
    <FilterBtn>
      <BtnIcon src={FilterIcon} alt="filter-icon" />
    </FilterBtn>
  );
};

export default FilterButton;
