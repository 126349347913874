import styled from 'styled-components';

export const ListAllUserPageContainer = styled.div`
  max-width: 1188px;
  width: 100%;
`;

export const TopContainer = styled.div`
  margin-bottom: 40px;
`;

export const InputWrapper = styled.div``;

export const PageTitle = styled.h1`
  font-size: 30px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 24px;
`;
