import UserAvatar1 from '../assets/images/avatars/avatar(2).png';
import UserAvatar2 from '../assets/images/avatars/avatar(3).png';
import UserAvatar3 from '../assets/images/avatars/avatar(4).png';
import UserAvatar4 from '../assets/images/avatars/avatar(5).png';
import UserAvatar5 from '../assets/images/avatars/avatar(6).png';
import UserAvatar6 from '../assets/images/avatars/avatar(7).png';
import UserAvatar7 from '../assets/images/avatars/avatar(8).png';
import UserAvatar8 from '../assets/images/avatars/avatar(9).png';
import UserAvatar9 from '../assets/images/avatars/avatar(10).png';
import UserAvatar10 from '../assets/images/avatars/avatar(11).png';
import UserAvatar11 from '../assets/images/avatars/avatar(12).png';
import UserAvatar12 from '../assets/images/avatars/avatar(13).png';
import UserAvatar13 from '../assets/images/avatars/avatar(14).png';
import UserAvatar14 from '../assets/images/avatars/avatar(15).png';
import UserAvatar15 from '../assets/images/avatars/avatar(16).png';
import UserAvatar16 from '../assets/images/avatars/avatar(17).png';
import UserAvatar17 from '../assets/images/avatars/avatar(18).png';
import UserAvatar18 from '../assets/images/avatars/avatar(19).png';

export const UserData = [
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar1,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar2,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar3,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar4,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar5,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar6,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar7,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar8,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar9,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar10,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar11,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar12,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar13,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar14,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar15,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar16,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar14,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar15,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar16,
    status: 'Approve',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar17,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
  {
    name: 'John Doe',
    email: 'emaladdress@gmail.com',
    avatar: UserAvatar18,
    status: 'Decline',
    phoneNumber: '+88056444698486',
    role: 'Your Role',
  },
];

export const tableColumns = [
  { field: 'name', header: 'Name' },
  { field: 'type', header: 'Role ' },
  { field: 'email', header: 'Email' },
  { field: 'phone', header: 'Phone Number' },
];

export const userRoles = ['Tutor', 'Student'];

export const avatarBgColors = [
  // '#ff8a65',
  '#f06292',
  // '#ba68c8',
  // '#9575cd',
  // '#64b5f6',
  // '#4db6ac',
  // '#aed581',
  // '#ffb74d',
  // '#90a4ae',
];
