import styled, { css } from 'styled-components';

const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const Image = styled(Content)`
  background-image: url(${({ url }) => url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
`;

export const Label = styled(Content)`
  align-items: center;
  justify-content: center;
  color: hwb(${({ hue }) => hue}deg 10% 40%);
  background: linear-gradient(
    135deg,
    hwb(${({ hue }) => hue}deg 90% 5%),
    hwb(${({ hue }) => hue}deg 75% 5%)
  );
  text-transform: uppercase;
`;

export const Name = styled.div`
  position: absolute;
  align-self: end;
  width: 100%;
  padding: 0.5em;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px #000;
  line-height: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  animation: hide 1s 3s forwards;
  opacity: 1;

  @keyframes hide {
    to {
      opacity: 0;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => Math.round(size * 0.32)}px;
  font-weight: 700;
  border-radius: 50%;
  z-index: 32;

  ${({ socialType }) => socialStyles[socialType]}
`;

const socialStyles = {
  instagram: css`
    background: linear-gradient(45deg, #ffc800, #d300c5);
    padding: 2px;
    ${Content} {
      border-radius: 50%;
      box-shadow: none;
      border: 2px solid #fff;
    }
  `,
};
