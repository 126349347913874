import React from 'react';
import Sidebar from '@components/side-bar';
import SidebarContent from './side-bar-content';
import { Header } from './styles';

const headerComponent = ({ setPage }) => {
  const PAGES = [
    {
      id: 'home',
      page: 'home',
      title: 'Home',
    },
    {
      id: 'about-us',
      page: 'about-us',
      title: 'About Us',
    },
    {
      id: 'contact-us',
      page: 'contact-us',
      title: 'Contact Us',
    },
    {
      id: 'terms-conditions',
      page: 'terms-conditions',
      title: 'Terms and Conditions',
    },
    {
      id: 'privacy-policy',
      page: 'privacy-policy',
      title: 'Privacy Policy',
    },
  ];
  return (
    <div>
      <Sidebar component={() => <SidebarContent PAGES={PAGES} setPage={setPage} />} />
      <Header>
        <div className="content">
          {PAGES.slice(0, 3).map((ele, index) => {
            return (
              <div style={{ padding: '0 20px' }}>
                <span key={`${ele.id}-${index}`} onClick={() => setPage(ele.page)}>
                  {ele.title}
                </span>
              </div>
            );
          })}
        </div>
        <div>
          <img className="log-img" alt="" src="./static/img/flower.png" />
          <h4 className="title">Teacher's only Tutoring</h4>
        </div>
        <div className="content">
          {PAGES.slice(3, 6).map((ele, index) => {
            return (
              <div style={{ padding: '0 20px' }}>
                <span key={`${ele.id}-${index}`} onClick={() => setPage(ele.page)}>
                  {ele.title}
                </span>
              </div>
            );
          })}
        </div>
      </Header>
    </div>
  );
};

export default headerComponent;
