import React from 'react';
import { BackArrow } from '@svgs';
import { Container, Images, VideoParent } from './styles';

const AboutUs = ({ page, setPage }) => {
  let images = [
    { src: 'static/img/img1.png', title: 'img1.png' },
    { src: 'static/img/img2.png', title: 'img2.png' },
    { src: 'static/img/img3.png', title: 'img3.png' },
    { src: 'static/img/img4.png', title: 'img4.png' },
    { src: 'static/img/img5.png', title: 'img5.png' },
    { src: 'static/img/img6.png', title: 'img6.png' },
    { src: 'static/img/img6.png', title: 'img6.png' },
    { src: 'static/img/img6.png', title: 'img6.png' },
  ];
  return (
    <>
      <Container>
        <VideoParent>
          <div className="child-left">
            {/* <video width="600" height="361" controls>
              <source src="video/video.mp4" type="video/mp4" />
            </video> */}
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/wUb4XuSjzm0?si=chL_gK2qJwTSv9ja"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="child-right">
            <div className="text-box">
              <h2 class="text-title">About Us</h2>
              <div className="text-description">
                {`Welcome to Teachers Only Tutoring, a leading tutoring company dedicated to providing
                exceptional educational support to students of all ages. Our team of highly qualified
                teachers turned independent contractors brings a wealth of knowledge, expertise, and
                passion to every tutoring session.
                At Teachers Only Tutoring, we understand that each student is unique, with their own
                learning style, strengths, and areas for improvement. That's why we believe in
                personalized learning experiences that empower students to reach their full potential.
                Whether your child needs help with a specific subject, exam preparation, or overall
                academic enrichment, our tutors are here to guide them towards success.
                What sets us apart is our commitment to excellence. All our independent contractors
                are experienced educators who have a deep understanding of the curriculum and
                instructional methods. They are not only subject matter experts but also skilled in
                adapting teaching strategies to cater to individual student needs. Our tutors are
                dedicated to fostering a positive learning environment that nurtures confidence, critical
                thinking, and a lifelong love for learning.
                We take great pride in our rigorous selection process for independent contractors. We
                believe that by selecting the best educators, we can deliver the highest quality tutoring
                services to our students.
                At Teachers Only Tutoring, we value the importance of ongoing professional
                development. Our tutors regularly engage in training and professional growth
                opportunities to stay updated with the latest educational practices and teaching
                methodologies. This ensures that they are equipped with the knowledge and skills
                needed to provide effective instruction and support to our students.
                Our goal goes beyond simply helping students achieve good grades. We strive to instill a
                lifelong love for learning, critical thinking, and problem-solving skills that will benefit
                them in all aspects of life. Our tutors are not just here to impart knowledge but also to
                mentor, inspire, and motivate students to become independent learners who are
                well-prepared for future academic challenges.
                We are dedicated to building strong partnerships with parents and guardians. We
                believe that open communication and collaboration between parents, tutors, and
                students are vital to the success of our tutoring programs. By working together, we can
                create a supportive and enriching learning environment that maximizes student
                potential.
                Thank you for considering Teachers Only Tutoring as your trusted partner in education.
                We look forward to helping your child excel academically and achieve their educational
                goals. Contact us today to learn more about our tutoring services and how we can
                support your child's learning journey.`}
              </div>
              {/* <div style={{ fontFamily: 'Roboto' }} className="text-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
              </div> */}
              <div className="back-next-btn">
                <div className="back-btn">
                  <BackArrow style={{ width: '100%' }} />
                </div>
                <div className="next-btn">
                  <BackArrow
                    style={{ transform: 'rotate(180deg)', color: 'white', width: '100%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </VideoParent>
        <Images>
          {images.map((index) => (
            <img
              src={index.src}
              title={index.title}
              alt="images"
              height="160px"
              width="160px"
              style={{ borderRadius: '5px' }}
            />
          ))}
        </Images>
      </Container>
    </>
  );
};

export default AboutUs;
