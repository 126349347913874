import React from 'react';
import { Container, Header, Bottom } from './styles';

const PrivacyPolicy = () => {
  const CONTENT = [
    {
      title: 'Information We Collect',
      description: `1.1 Personal Information:
      We may collect personal information, including but not limited to, your name, address,
      email address, phone number, and payment information when you voluntarily provide it
      to us.
      1.2 Collection of Information and Academic Records from Students:
      If you are a student, we may collect additional personal information such as age, grade
      level, academic records, and learning preferences to better understand your educational
      needs and provide appropriate tutoring services.
      1.3 Information from Independent Contractors:
      If you are an independent contractor working with us as a tutor, we may collect personal
      information such as your name, contact details, qualifications, background check
      information, and payment details to facilitate the tutoring services and ensure
      compliance with applicable laws.
      1.4 Consent:
      In order to provide effective tutoring services, we may collect academic records,
      including but not limited to, transcripts, report cards, test scores, and other educational
      information. We collect this information with the explicit consent of the student or their
      parent/guardian, where applicable.
      1.5 Voluntary Disclosure:
      Students or their parents/guardians have the choice to voluntarily disclose academic
      records for the purpose of evaluating their educational needs, tracking progress, and
      tailoring tutoring sessions to their specific requirements.`,
    },
    {
      title: 'Use of Information & Academic Records:',
      description: `2.1 Personalized Tutoring:
      We use academic records to assess a student's strengths, weaknesses, and learning
      goals. This information enables us to customize tutoring sessions and develop tailored
      educational plans to enhance the student's academic performance.
      2.2 Progress Tracking:
      Academic records help us track a student's progress over time. By analyzing the data,
      we can evaluate the effectiveness of our tutoring programs and make necessary
      adjustments to improve educational outcomes.
      2.3 Communication with Parents/Guardians:
      We may use academic records to communicate with parents/guardians regarding a
      student's progress, areas of improvement, and overall educational development. This
      helps facilitate meaningful discussions and collaboration between tutors, students, and
      parents/guardians.
      2.4 Internal Operations:
      We may use aggregated and anonymized academic records for internal purposes, such
      as data analysis, research, and improving our tutoring programs. This ensures the
      continual enhancement of our educational services.
      2.1 Providing Services:
      We use the collected personal information to provide tutoring services to students and
      communicate with them regarding scheduling, progress, and related matters. For
      independent contractors, we use the information to facilitate the tutoring services,
      process payments, and maintain accurate records.
      2.2 Internal Operations:
      We may use personal information for internal purposes such as data analysis,
      improving our services, developing new offerings, and conducting research to enhance
      the effectiveness of our tutoring programs.
      2.3 Marketing and Communication:
      With your consent, where required by applicable law, we may use your personal
      information to send promotional materials, updates, newsletters, or other
      communications about our services. You have the right to opt-out of receiving such
      communications at any time.`,
    },
    {
      title: 'Disclosure of Information & Academic Records:',
      description: `3.1 Tutor-Student Relationship:
      Academic records may be shared with tutors who are directly involved in providing
      tutoring services to students. Tutors will have access to the relevant academic records
      necessary for designing appropriate lesson plans and monitoring progress.
      3.2 Service Providers:
      We may share personal information with trusted third-party service providers who assist
      us in operating our business, including payment processors, IT support, and customer
      service providers. These service providers are bound by confidentiality obligations and
      are only permitted to use personal information for the specific purposes we dictate.
      3.3 Legal Requirements:
      We may disclose personal information if required to comply with applicable laws,
      regulations, legal processes, or enforceable governmental requests. We may also
      disclose personal information to protect our rights, privacy, safety, or property, or that of
      our users, contractors, or the public.
      3.4 Business Transfers:
      In the event of a merger, acquisition, or any form of sale of some or all of our assets,
      personal information may be transferred as part of the transaction. We will notify you
      before your personal information becomes subject to a different privacy policy.`,
    },
    {
      title: 'Data Security:',
      description: `We take reasonable measures to protect personal information from unauthorized
      access, use, or disclosure. However, no method of transmission or storage is 100%
      secure. We cannot guarantee absolute security, and you acknowledge and accept any
      potential risks associated with the transmission and storage of personal information.`,
    },
    {
      title: 'Data Retention:',
      description: `We retain personal information for as long as necessary to fulfill the purposes outlined
      in this Privacy Policy, unless a longer retention period is required or permitted by law. We
      will securely dispose of personal information when it is no longer needed.`,
    },
    {
      title: 'Your Rights:',
      description: `You have certain rights regarding your personal information, including the right to
      access, correct, update, or delete your personal information. If you have any questions
      or requests regarding your personal information, please contact us using the
      information provided below.`,
    },
    {
      title: 'Changes to the Privacy Policy:',
      description: `Teachers Only Tutoring Company reserves the right to update or modify this Privacy
      Policy at any time. We will post the updated Privacy Policy on our website and indicate
      the effective date. Your continued use of our services after the changes become
      effective constitutes your acceptance of the revised Privacy Policy.`,
    },
  ];
  return (
    <>
      <Container>
        <Header>
          <span className="title">
            Privacy Policy for Teachers Only Tutoring Company with Independent Contractors
          </span>
          <div className="date">Last Updated on January 2024</div>
        </Header>
        <Bottom>
          {CONTENT.map((item, index) => {
            return (
              <div className="box">
                <div className="title-text">{`${index + 1}. ${item.title}`}</div>
                <div className="description-text">{item.description}</div>
              </div>
            );
          })}
        </Bottom>
      </Container>
    </>
  );
};
export default PrivacyPolicy;
