import styled from 'styled-components';

export const AddUserBtn = styled.button`
  width: 160px;
  height: 56px;
  font-size: 18px;
  line-height: 23px;
  color: #ffffff;
  background: #010101;
  border: 1.5px solid #000000;
  border-radius: 5px;
  margin-left: 31px;
`;

export const BtnIcon = styled.img`
  width: ${(props) => props.width || '24px'};
  height: ${(props) => props.height || '24px'};
  margin-left: ${(props) => props.marginLeft || '0'};
  margin-right: ${(props) => props.marginRight || '0'};
`;

export const FilterBtn = styled.button`
  width: 64px;
  height: 56px;
  padding: 16px 20px;
  border: 1.5px solid #000000;
  background: #fff;
  border-radius: 5px;
  margin-left: 30px;
`;

export const NormalBtn = styled.button`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '56px'};
  font-size: ${(props) => props.fontSize || '20px'};
  line-height: 150%;
  color: #ffffff;
  background: #010101;
  border: 1.5px solid #000000;
  border-radius: 5px;
  text-transform: capitalize;
  margin-top: ${(props) => props.marginTop || '0'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  margin-right: ${(props) => props.marginRight || '0'};
  padding: ${(props) => props.padding || '0'};
  &.bordered {
    color: #010101;
    background: #fff;
  }
`;
