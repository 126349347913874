import React, { useState } from 'react';
import NormalButton from '../buttons/NormalButton';
import {
  DescriptionWrapper,
  FeedbackCardWrapper,
  ResponseInput,
  ResponseTitle,
  TopWrapper,
  UserAvatar,
  UserDescription,
  UserInfoWrapper,
  UserName,
  UserNameAvatar,
} from './FeedbackCard.styled';

const FeedbackCard = ({ cardData, respondFeedback, setProfileData, setShowProfileModal }) => {
  const [responseText, setResponseText] = useState('');

  const handleClick = async () => {
    if (responseText) {
      await respondFeedback(cardData.id, responseText);
      setResponseText('');
    }
  };

  return (
    <FeedbackCardWrapper>
      <TopWrapper className="d-flex justify-content-between align-items-center">
        <UserInfoWrapper className="d-flex align-items-center">
          {cardData.user.avatar ? (
            <UserAvatar src={cardData.user.avatar} alt="user-avatar" />
          ) : (
            <UserNameAvatar
              className="d-flex align-items-center justify-content-center"
              backgroundColor={cardData.user.name ? '#f06292' : '#000'}
              fontColor={'#fff'}
            >
              {cardData.user.name ? cardData.user.name[0] : 'N'}
            </UserNameAvatar>
          )}
          <UserName>{cardData.user.name}</UserName>
        </UserInfoWrapper>
        <NormalButton
          bordered
          content="View profile"
          width="62px"
          height="20px"
          fontSize="12px"
          onClick={() => {
            setShowProfileModal(true);
            setProfileData(cardData.user);
          }}
        />
      </TopWrapper>
      <DescriptionWrapper>
        <UserDescription>{cardData.message}</UserDescription>
      </DescriptionWrapper>
      <ResponseTitle>Your response</ResponseTitle>
      <ResponseInput
        placeholder="write your response here"
        value={responseText}
        onChange={(e) => setResponseText(e.target.value)}
      />
      <NormalButton
        content="send"
        height="40px"
        fontSize="14px"
        bordered={!responseText}
        onClick={handleClick}
      />
    </FeedbackCardWrapper>
  );
};

export default FeedbackCard;
