import CancelIcon from '../assets/images/icons/cancel-icon.svg';
import CancelWhiteIcon from '../assets/images/icons/cancel-white-icon.svg';
import CheckCircleIcon from '../assets/images/icons/check-circle-icon.svg';
import CheckCircleWhiteIcon from '../assets/images/icons/check-circle-white-icon.svg';

export const StatusButtons = [
  {
    status: 'all',
    name: 'All requests',
  },
  {
    status: 'approve',
    name: 'Approve requests',
    icon: CheckCircleIcon,
    activeIcon: CheckCircleWhiteIcon,
  },
  {
    status: 'decline',
    name: 'Declined requests',
    icon: CancelIcon,
    activeIcon: CancelWhiteIcon,
  },
];

export const tableColumns = [
  { field: 'name', header: 'Name' },
  { field: 'email', header: 'Email' },
  { field: 'phone', header: 'Phone Number' },
];
