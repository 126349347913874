import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 1100px;
  margin: 0 auto;
  height: 100vh;
  overflow: scroll;
  font-family: 'Caveat';
  @media (min-width: 481px) and (max-width: 767px) {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ul li {
    list-style-type: none;
    display: inline-block;
    padding: 20px;
  }
  a {
    width: 30px;
    height: 44px;
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 44px;
    color: #000000;
    text-decoration: none;
  }
  span {
    width: 30px;
    height: 44px;
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 44px;
    color: #000000;
    cursor: pointer;
  }
`;
export const VideoParent = styled.div`
  width: 100%;
  display: flex;
  video {
    width: 100%;
    height: auto;
  }
  .child-left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .child-right {
    width: 50%;
    font-family: 'Caveat';
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-box {
    padding: 60px;
  }
  .back-next-btn {
    display: flex;
    gap: 10px;
    padding: 20px 0px;
  }
  .back-btn {
    width: 24px;
    height: 24px;
    border: 2px solid black;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  .next-btn {
    width: 24px;
    height: 24px;
    background: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
    .child-left {
      width: 100%;
      height: auto;
    }
    .child-right {
      width: 100%;
      height: auto;
    }
  }
`;

export const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 50px 10px;
  img {
    margin: 10px;
  }
  @media (max-width: 767px) {
    justify-content: center;
    margin: 0 10px;
  }
`;
