import React, { useEffect, useMemo, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { main_api } from '../api/auth';
import CancelIcon from '../assets/images/icons/times-square-icon.svg';
import TrashIcon from '../assets/images/icons/trash-icon.svg';
import UploadFileBox from '../components/UploadFileBox';
import NormalButton from '../components/buttons/NormalButton';
import {
  UploadedItemPanel,
  UploadItem,
  UploadItemButton,
  UploadItemIcon,
  UploadItemTitle,
  UploadWrapper,
  UploadPageTitle,
  UploadPageWrapper,
  UploadItemRightWrapper,
  UploadCheckWrapper,
  UploadCheckPanel,
  UploadCheckbox,
  UploadingPanel,
  UploadingItemBox,
  UploadProgressWrapper,
} from './UploadPage.styled';

let source = axios.CancelToken.source();

const UploadPage = () => {
  const [files, setFiles] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [currentUploadFile, setCurrentUploadFile] = useState('');
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [postToMobile, setPostToMobile] = useState(false);
  const [postToLandingPage, setPostToLandingPage] = useState(false);

  const axiosConfig = {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setUploadingProgress(percentCompleted);
    },
    cancelToken: source.token,
  };

  const getAllBlogs = async () => {
    const { data } = await main_api.get(`/blog/`);
    setBlogs([...data]);
  };

  const handleUpload = async () => {
    if (files.length) {
      setUploading(true);
      files.map((file) => {
        setCurrentUploadFile(file.path);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('mobile_app', postToMobile);
        formData.append('web_app', postToLandingPage);
        main_api
          .post('/blog/', formData, axiosConfig)
          .then((res) => {
            console.log(res.data);
            toast.success('Upload successful');
            setBlogs([...blogs, res.data]);
            setUploadingProgress(0);
            setTimeout(() => {
              setUploading(false);
              setFiles([]);
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              if (uploading) {
                toast.error('Upload failed');
              } else {
                toast.info('Upload cancelled');
              }
            }, 500);
          });
      });
    }
  };

  const deleteFile = () => {
    setFiles([]);
  };

  const deleteBlog = async (id) => {
    setBlogs((val) => [...val.filter((blog) => blog.id !== id)]);
    await main_api
      .delete(`/blog/${id}/`)
      .then((res) => {
        toast.success('Blog deleted successfully');
      })
      .catch((err) => {
        toast.error('Blog delete failed');
      });
  };

  const handleUploadingCancel = () => {
    source.cancel('User canceled the request');
    setUploadingProgress(0);
    setUploading(false);
    source = axios.CancelToken.source();
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <UploadPageWrapper className="d-flex justify-content-between">
      <UploadWrapper>
        <UploadPageTitle>Upload</UploadPageTitle>
        <UploadFileBox setFiles={setFiles} />
        {uploading ? (
          <UploadingPanel>
            <UploadItemTitle>Uploading</UploadItemTitle>
            <UploadingItemBox className="d-flex align-items-center justify-content-between">
              {files[0].path}
              <UploadItemButton onClick={handleUploadingCancel}>
                <UploadItemIcon width="24px" height="24px" src={CancelIcon} alt="cancel-icon" />
              </UploadItemButton>
              <UploadProgressWrapper>
                <ProgressBar now={uploadingProgress} />
              </UploadProgressWrapper>
            </UploadingItemBox>
          </UploadingPanel>
        ) : (
          ''
        )}
        {files.length ? (
          <UploadedItemPanel>
            <UploadItemTitle>Uploaded</UploadItemTitle>
            <UploadItem>
              {files[0].path}
              <UploadItemButton onClick={deleteFile}>
                <UploadItemIcon src={TrashIcon} alt="trash-icon" />
              </UploadItemButton>
            </UploadItem>
            <UploadCheckPanel className="d-flex align-items-center">
              <UploadCheckWrapper className="d-flex align-items-center">
                <UploadCheckbox onChange={(e) => setPostToMobile(e.target.checked)} />
                Post to mobile app
              </UploadCheckWrapper>
              <UploadCheckWrapper className="d-flex align-items-center">
                <UploadCheckbox onChange={(e) => setPostToLandingPage(e.target.checked)} />
                Post to a landing page
              </UploadCheckWrapper>
            </UploadCheckPanel>
          </UploadedItemPanel>
        ) : (
          ''
        )}
        <NormalButton
          content="Upload Files"
          marginTop="80px"
          onClick={handleUpload}
          bordered={!files.length}
        />
      </UploadWrapper>
      <UploadWrapper>
        <UploadItemTitle>All Files</UploadItemTitle>
        {blogs.map((blog, index) =>
          blog.file ? (
            <UploadItem key={index}>
              {blog.file.split('files/')[1]}
              <UploadItemRightWrapper>
                {blog.mobile_app ? '-To Mobile App' : '-To Landing Page'}
                <UploadItemButton marginLeft="37px" onClick={() => deleteBlog(blog.id)}>
                  <UploadItemIcon src={TrashIcon} alt="trash-icon" />
                </UploadItemButton>
              </UploadItemRightWrapper>
            </UploadItem>
          ) : (
            ''
          )
        )}
      </UploadWrapper>
    </UploadPageWrapper>
  );
};

export default UploadPage;
