/* eslint-disable prettier/prettier */
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { BrowserRouter } from 'react-router-dom';
import 'react-virtualized/styles.css';
import { ErrorBoundary } from './components';
import Routes from './containers';
import GlobalStyle from './styles/global-style';
import { getQueries } from './utils';

const getScreenInfo = () => {
  const { innerWidth: width, innerHeight: height } = window;
  document.body.style.setProperty('--width', `${width}px`);
  document.body.style.setProperty('--height', `${height}px`);
  window.scrollTo(0, 0);
};

const updateTheme = () => {
  let { theme } = getQueries();
  if (theme !== 'dark' && theme !== 'light') {
    theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  theme = 'light';
  if (theme === 'dark') {
    document.body.classList.add('dark');
  } else {
    document.body.classList.remove('dark');
  }
  document
    .querySelector('meta[name="theme-color"]')
    .setAttribute('content', theme === 'dark' ? '#000000' : '#ffffff');
};

document.addEventListener('dblclick', (e) => e.preventDefault());
document.addEventListener('gesturestart', (e) => e.preventDefault());
window.addEventListener('resize', getScreenInfo);
window.addEventListener('orientationchange', getScreenInfo);
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);
getScreenInfo();
updateTheme();

const App = () => {
  return (
    <ErrorBoundary>
      <GlobalStyle />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
