import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadFileImg from '../assets/images/icons/upload-file-icon.svg';
import {
  UploadFileBrowseLabel,
  UploadFileDragWrapper,
  UploadFileFormates,
  UploadFileIcon,
  UploadFileInput,
  UploadFileTitle,
} from './UploadFileBox.styled';

const UploadFileBox = ({ setFiles }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...acceptedFiles]);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/gif': [],
      'video/mp4': [],
      'application/pdf': [],
      'application/postscript': [],
      'application/vnd.ms-powerpoint': [],
    },
  });

  return (
    <UploadFileDragWrapper {...getRootProps()}>
      <UploadFileInput {...getInputProps()} />
      <UploadFileIcon src={UploadFileImg} alt="upload-file-icon" />
      <UploadFileTitle>
        Drag & drop files or
        <UploadFileBrowseLabel>
          Browse
          {/* <UploadFileBrowseInput /> */}
        </UploadFileBrowseLabel>
      </UploadFileTitle>
      <UploadFileFormates>
        Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
      </UploadFileFormates>
    </UploadFileDragWrapper>
  );
};

export default UploadFileBox;
