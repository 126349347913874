import React from 'react';
import { toast } from 'react-toastify';
import { main_api } from '../../api/auth';
import DeleteUserImage from '../../assets/images/delete-user-image.png';
import CloseSquareIcon from '../../assets/images/icons/close-square-icon.svg';
import NormalButton from '../buttons/NormalButton';
import {
  CloseIcon,
  CloseModalBtn,
  DeleteUserImageWrapper,
  DeleteUserImg,
  ModalContentWrapper,
  ModalTitle,
  ModalWrapper,
} from './UserModal.styled';

const DeleteUserModal = ({ show, onClose, actionUserId, setOriginUserData }) => {
  const handleDelete = async () => {
    await main_api
      .delete('/admin/users/' + actionUserId + '/')
      .then(() => {
        toast.success('User deleted successfully');
        setOriginUserData((data) => data.filter((user) => user.id !== actionUserId));
        onClose();
      })
      .catch((err) => {
        toast.error('Something went wrong');
      });
  };

  return (
    <ModalWrapper show={show} onHide={onClose} centered>
      <ModalContentWrapper>
        <CloseModalBtn onClick={onClose}>
          <CloseIcon src={CloseSquareIcon} alt="close-icon" />
        </CloseModalBtn>
        <ModalTitle className="text-center">Delete User</ModalTitle>
        <DeleteUserImageWrapper className="d-flex justify-content-center">
          <DeleteUserImg src={DeleteUserImage} alt="delete-image" />
        </DeleteUserImageWrapper>
        <NormalButton onClick={handleDelete} content="Confirm" />
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export default DeleteUserModal;
