import React, { useEffect, useState } from 'react';
import { main_api } from 'adminPanel/api/auth';
import { BtnIcon } from 'adminPanel/components/buttons/Button.styled';
import AddIcon from '../assets/images/icons/add-black-icon.svg';
import NormalButton from '../components/buttons/NormalButton';
import { feeData } from '../config/fee.config';
import { FeedPageContainer, FeeInput, FeeItemWrapper, PageTitle } from './Fee.styled';

const FeePage = () => {
  const [fees, setFees] = useState([]);
  const [updatedFees, setUpdatedFees] = useState([]);
  const [addedFees, setAddedFees] = useState([]);

  const getAllFees = async () => {
    const { data } = await main_api.get('/admin/fees/');
    setFees(data);
  };

  const handleInput = (e, item, index) => {
    if (!updatedFees.find((item) => item === index)) {
      setUpdatedFees([...updatedFees, index]);
    }
    const { value } = e.target;
    const len = value.length;
    const dolarIndex = value.indexOf('$');
    const number = value.substring(1, len);

    if (dolarIndex !== 0 || dolarIndex === -1 || isNaN(number)) {
      e.target.value = '$';
      e.target.setSelectionRange(dolarIndex, dolarIndex);
    } else {
      item.amount = +number;
      setFees([...fees]);
    }
  };

  const addNewFeesItem = () => {
    setAddedFees([...addedFees, { name: '', amount: 0, new: true }]);
  };

  const addNewFees = () => {
    addedFees
      .filter((item) => item.name)
      .map(async (item) => {
        const formData = new FormData();
        formData.append('name', item.name);
        formData.append('amount', item.amount);
        formData.append('active', true);
        await main_api.post('/admin/fees/', formData).then((res) => {
          getAllFees();
          setAddedFees([]);
        });
      });
  };

  const updateFees = () => {
    updatedFees.map(async (index) => {
      const item = fees[index];
      const formData = new FormData();
      formData.append('name', item.name);
      formData.append('amount', item.amount);
      formData.append('active', item.active);
      await main_api.patch('/admin/fees/' + item.id + '/', formData).then((res) => {
        getAllFees();
        setUpdatedFees([]);
      });
    });
  };

  const saveResult = () => {
    updateFees();
    addNewFees();
  };

  useEffect(() => {
    getAllFees();
  }, []);

  return (
    <FeedPageContainer>
      <PageTitle>App Fees</PageTitle>
      {[...fees, ...addedFees].map((item, index) => (
        <FeeItemWrapper key={index} className="d-flex align-items-center justify-content-between">
          {item.new ? (
            <FeeInput
              value={item.name}
              width="200px"
              placeholder="Fee Name"
              onChange={(e) => {
                item.name = e.target.value;
                setAddedFees([...addedFees]);
              }}
            />
          ) : (
            item.name
          )}
          <FeeInput value={'$' + item.amount} onChange={(e) => handleInput(e, item, index)} />
        </FeeItemWrapper>
      ))}
      <NormalButton
        width="570px"
        height="40px"
        marginBottom="24px"
        fontSize="14px"
        content="Save"
        onClick={saveResult}
      />
      <NormalButton
        width="570px"
        height="40px"
        fontSize="14px"
        bordered
        onClick={addNewFeesItem}
        content={
          <>
            <BtnIcon
              src={AddIcon}
              width={'16px'}
              height={'16px'}
              marginRight={'8px'}
              alt="add-icon"
            />
            Add Fee Item
          </>
        }
      />
    </FeedPageContainer>
  );
};

export default FeePage;
