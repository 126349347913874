import UserAvatar1 from '../assets/images/avatars/avatar(2).png';
import UserAvatar2 from '../assets/images/avatars/avatar(3).png';

export const tableColumns = [
  { field: 'name', header: 'Name' },
  { field: 'rating', header: 'Rating ' },
  { field: 'grade', header: 'Grade' },
  { field: 'tutor_preference', header: 'Tutor preference' },
  { field: 'area', header: 'Area' },
];

export const reportTableData = [
  {
    avatar: UserAvatar1,
    name: 'John doe',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar2,
    name: 'Sofia Annisa',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar1,
    name: 'John doe',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar2,
    name: 'Sofia Annisa',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar1,
    name: 'John doe',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar2,
    name: 'Sofia Annisa',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar1,
    name: 'John doe',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar2,
    name: 'Sofia Annisa',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar1,
    name: 'John doe',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
  {
    avatar: UserAvatar2,
    name: 'Sofia Annisa',
    rating: '5 Star',
    grade: 'Eight Grade',
    tutor_preference: 'Virtual',
    area: '8-10 miles',
  },
];
