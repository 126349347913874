import React from 'react';
import { Outlet } from 'react-router-dom';
import { MainLayoutContainer, PageContainer } from './MainLayout.styled';
import Sidebar from './Sidebar';

const MainLayout = () => {
  return (
    <MainLayoutContainer className="d-flex">
      <Sidebar />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </MainLayoutContainer>
  );
};

export default MainLayout;
