import styled from 'styled-components';

export const Container = styled.div`
  width: 1100px;
  margin: 0 auto;
  height: 100vh;
  overflow: scroll;
  @media (max-width: 767px) {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
`;
export const Header = styled.div`
  width: 100%;
  .back-parent {
    width: 100%;
    display: flex;
  }
  .back-svg {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 100px;
  }
  h5 {
    font-family: 'Caveat';
    font-size: 25px;
    margin: 0;
  }
  .contact-help {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text-title {
    padding: 20px;
    font-family: 'Caveat';
  }
  .text-description {
    font-family: 'Caveat';
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
`;
export const Bottom = styled.div`
  margin: 30px auto;
  width: 50%;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .two-fields {
    display: flex;
    gap: 10px;
  }
  .phone-number-box {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .email-box {
    flex: 1;
    width: 50%;
  }
  .single-field {
    display: flex;
    margin-top: 20px;
  }
  .phone {
    background: none;
    border: 1px solid #b2b2b2;
    outline: none;
    color: black;
    height: 35px;
    border-radius: 5px;
  }

  .email {
    background: none;
    border: 1px solid #b2b2b2;
    outline: none;
    color: black;
    height: 35px;
    border-radius: 5px;
    width: 100%;
  }
  .desc {
    background: none;
    border: 1px solid #b2b2b2;
    outline: none;
    color: black;
    width: 860px;
    height: 171px;
    border-radius: 5px;
    ${
      '' /* text-align: left;
    vertical-align: top; */
    }
  }
  .desc-div {
    width: 100%;
  }
  .desc-div textarea {
    border: 1px solid #b2b2b2;
    width: 100%;
    border-radius: 5px;
  }

  .btn-box {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }

  .send-btn {
    width: 190px;
    height: 56px;
    background: #010101;
    border-radius: 5px;
    color: white;
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }
  label {
    font-family: 'Caveat';
    font-weight: 700;
    font-size: 20px;
    color: #010101;
    margin-bottom: 10px;
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
    .two-fields {
      flex-direction: column;
    }
    .phone-number-box {
      width: 100%;
    }
    .email-box {
      width: 100%;
    }
    .send-btn {
      height: 40px;
    }
  }
`;
