import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { main_api } from '../api/auth';
import FeedbackCard from '../components/cards/FeedbackCard';
import ProfileModal from '../components/modals/ProfileModal';
// import { feedbackData } from '../config/feedback.cofig';
import { FeedbackPageContainer } from './Feedback.styled';

const FeedbackPage = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profileData, setProfileData] = useState('');

  const sortDate = (date1, date2) => {
    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    } else {
      return 0;
    }
  };

  const getAllFeedback = async () => {
    const { data } = await main_api.get('/admin/feedback/');
    setFeedbackData([
      ...data.sort((a, b) => sortDate(new Date(b.created_at), new Date(a.created_at))),
    ]);
  };

  const respondFeedback = async (feedbackId, response) => {
    const formData = new FormData();
    formData.append('feedback', feedbackId);
    formData.append('response', response);
    await main_api
      .post('/admin/feedback/respond/', formData)
      .then((res) => toast.success('Respond Successfully'))
      .catch((err) => toast.error('Respond Failed'));
  };

  useEffect(() => {
    getAllFeedback();
  }, []);

  return (
    <FeedbackPageContainer className="d-flex justify-content-between flex-wrap">
      {feedbackData.map((item, index) => (
        <FeedbackCard
          cardData={item}
          setProfileData={setProfileData}
          setShowProfileModal={setShowProfileModal}
          key={index}
          respondFeedback={respondFeedback}
        />
      ))}
      <ProfileModal
        show={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        data={profileData}
      />
    </FeedbackPageContainer>
  );
};

export default FeedbackPage;
