import moment from 'moment';
import { IMAGE_BASE_URL, MOCK_IMAGE, DATE_FORMAT } from '@utils/constants';
import './string';

/* ============================================ */
//  check mobile
/* ============================================ */

export const isMobile = /\b(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)\b/i.test(
  navigator.userAgent
);

/* ============================================ */
//  format fiat value
/* ============================================ */

export const formatFiatValue = (fiat, fractionDigits = 2) => {
  const value = Number(fiat);
  return value.toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

/* ============================================ */
//  getQueries
/* ============================================ */

export const getQueries = () => {
  return window.location.search
    .replace(/^\?/, '')
    .split('&')
    .reduce((obj, str) => {
      if (!str) return obj;
      const pair = str.split('=');
      return { ...obj, [pair[0]]: pair[1] };
    }, {});
};

/* ============================================ */
//  get random item from array
/* ============================================ */
export function getRandomItem(arr) {
  const randomIndex = Math.floor(Math.random() * arr.length);
  const item = arr[randomIndex];
  return item;
}

/* ============================================ */
//  date utilities
/* ============================================ */
export const getDateString = (timestamp) => {
  return moment(new Date(timestamp)).format(DATE_FORMAT);
};

/* ============================================ */
//  date utilities
/* ============================================ */
export const getImageUrl = (fileName) => {
  return fileName ? `${IMAGE_BASE_URL}/${fileName}` : `${MOCK_IMAGE}/food.png`;
};
