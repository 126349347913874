import styled from 'styled-components';

export const PaginationContainer = styled.div`
  margin-top: 88px;
`;

export const PaginationText = styled.p`
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

export const PaginationWrapper = styled.div``;
export const PaginationItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #f0f0f0;
  border-radius: 5px;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  border: 1.5px solid transparent;
  &:not(:last-child) {
    margin-right: 8px;
  }
  &.active {
    border-color: #000;
  }
`;
