import React from 'react';
import { useState } from 'react';
import { Modal } from '@components';
import { BarsSvg } from '@svgs';
import { MainWrapper, Wrapper, ContentWrapper } from './style';

const Sidebar = ({ component: Component, ...props }) => {
  const [showbar, setShowbar] = useState(false);

  return (
    <MainWrapper>
      <BarsSvg onClick={() => setShowbar(!showbar)} />
      <Modal
        animation="left"
        hideCloseButton
        overlayStyle={{
          backgroundColor: 'var(--overlay)',
        }}
        style={{
          backgroundColor: '#fff',
          'box-shadow': '3px -1px 14px 5px #00000050',
          borderRadius: '0 10px 10px 0',
          backdropFilter: 'blur(20px) invert(0.15)',
          top: 0,
          height: '100%',
          width: '70%',
        }}
        component={() => (
          <Wrapper>
            <ContentWrapper>{Component && <Component {...props} />}</ContentWrapper>
          </Wrapper>
        )}
        visible={showbar}
        onClose={() => setShowbar(false)}
      />
    </MainWrapper>
  );
};

export default Sidebar;
