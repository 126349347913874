import styled, { css, keyframes } from 'styled-components';

const oldAni = keyframes`
  100% {
    transform: translateY(-100%);
  }
`;

const newAni = keyframes`
  0% {
    transform: translateY(100%);
  }
`;

export const Wrapper = styled.span.attrs({ className: 'ani-number' })`
  position: relative;
  display: inline-flex;
  font-variant-numeric: tabular-nums;
  line-height: 1.1;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.1em;
    z-index: 1;
    color: var(--bg-color);
  }
`;

export const Item = styled.div`
  position: relative;
  line-height: 0.95;

  .old {
    position: absolute;
  }

  ${(props) =>
    props.isUpdated &&
    css`
      .old {
        animation: ${oldAni} ${props.aniTime}ms linear forwards;
      }

      .new {
        animation: ${newAni} ${props.aniTime}ms linear forwards;
      }
    `}
`;
