import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .modal-dialog {
    max-width: 938px;
    width: 100%;
    margin: auto;
    height: 100vh;
    padding: 20px;
  }
  .modal-content {
    border-radius: 0;
  }
`;

export const ModalContentWrapper = styled(Modal.Body)`
  padding: 105px 155px;
  text-align: center;
`;

export const ModalImage = styled.img`
  width: 347px;
  margin-bottom: 40px;
`;

export const ModalTitle = styled.h2`
  font-weight: 700;
  font-size: 32px;
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 16px;
`;
export const ModalDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 57px;
`;

export const ButtonWrapper = styled.div`
  max-width: 350px;
  margin: auto;
`;
