import styled from 'styled-components';

export const FeedbackCardWrapper = styled.div`
  padding: 24px;
  border: 1.5px solid #000000;
  border-radius: 5px;
  width: 350px;
  height: 279px;
  margin-bottom: 24px;
`;

export const TopWrapper = styled.div`
  margin-bottom: 8px;
`;

export const UserInfoWrapper = styled.div``;

export const UserAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 18px;
`;

export const UserNameAvatar = styled.span`
  width: 32px;
  height: 32px;
  padding-right: 5px;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 18px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
  margin-right: 18px;
`;

export const UserName = styled.h3`
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #000000;
`;

export const DescriptionWrapper = styled.div`
  border-radius: 5px;
  background: #ededed;
  padding: 8px;
  max-height: 48px;
  margin-bottom: 8px;
`;

export const UserDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  color: #000000;
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const ResponseTitle = styled.h3`
  font-size: 16px;
  line-height: 1;
  color: #000000;
  margin-bottom: 8px;
`;

export const ResponseInput = styled.textarea`
  width: 100%;
  height: 63px;
  border: 1.5px solid #000000;
  outline: 0;
  border-radius: 5px;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
  color: #010101;
  resize: none;
  margin-bottom: 8px;
  &::placeholder {
    color: #010101;
  }
`;
