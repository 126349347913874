/* eslint-disable prettier/prettier */
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from 'adminPanel/layout';
import ApprovedAccountsPage from 'adminPanel/pages/ApprovedAccounts';
import FeePage from 'adminPanel/pages/Fee';
import FeedbackPage from 'adminPanel/pages/Feedback';
import ListAllUserPage from 'adminPanel/pages/ListAllUsers';
import ReportPage from 'adminPanel/pages/ReportPage';
import UploadPage from 'adminPanel/pages/UploadPage';
import 'adminPanel/styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Loader } from '@components';
import AppDeeplink from './app-deeplink';
import Main from './main';
import useLoader from './sharedHooks/useLoader';

const AppRoutes = () => {
  const { loading } = useLoader();

  return (
    <>
      {loading && <Loader />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/app/:link" element={<AppDeeplink />} />
        <Route path="/admin" element={<MainLayout />}>
          <Route index element={<ListAllUserPage />} />
          <Route path="approved-accounts" element={<ApprovedAccountsPage />} />
          <Route path="feedback" element={<FeedbackPage />} />
          <Route path="upload" element={<UploadPage />} />
          <Route path="report" element={<ReportPage />} />
          <Route path="fees" element={<FeePage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
