/* eslint-disable prettier/prettier */
import { combineReducers } from 'redux';
import AuthReducer from './auth.reducer';
import SharedStore from './shared.reducer';

const rootReducer = combineReducers({
  AuthReducer,
  SharedStore,
});

export default rootReducer;
