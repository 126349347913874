import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateX(${({ currentIndex }) => currentIndex * -100}%);
  transition: transform 500ms var(--easeOutQuart);
`;

export const Page = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(${({ index }) => index * 100}%);
`;
