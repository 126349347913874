import styled from 'styled-components';

export const SearchContainer = styled.label`
  position: relative;
  max-width: ${(props) => props.maxWidth || '665px'};
  height: 56px;
  width: 100%;
`;

export const InputIcon = styled.img`
  width: ${(props) => props.width || '24px'};
  height: ${(props) => props.height || '24px'};
  position: absolute;
  top: 16px;
  left: 24px;
`;

export const CustomInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: 1.5px solid #000000;
  border-radius: 5px;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  padding-left: 56px;
  &::placeholder {
    color: #000000;
  }
`;
