import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--line-color1);
  background: rgba(255, 255, 255, 0.7);
  color: #2444c4;
  font-size: 14px;
  font-weight: 600;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 300ms;
  z-index: 1000;

  button {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 0 10px;

    svg {
      width: 12px;
      color: #2444c4;
    }
  }
`;
