import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { phone } from 'phone';
import { main_api } from '../../api/auth';
import CloseSquareIcon from '../../assets/images/icons/close-square-icon.svg';
import NormalButton from '../buttons/NormalButton';
import {
  CloseIcon,
  CloseModalBtn,
  ModalContentWrapper,
  ModalFormWrapper,
  ModalInput,
  ModalTitle,
  ModalWrapper,
} from './UserModal.styled';

const EditUserModal = ({ show, onClose, actionUserId, originUserData, setOriginUserData }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const userData = originUserData.find((item) => item.id === actionUserId);
    const formData = new FormData(e.target);
    if (userData.email !== email) {
      formData.append('email', email);
    }
    if (phoneNumber) {
      if (phone(phoneNumber).isValid) {
        formData.append('phone', phone(phoneNumber).phoneNumber);
      } else {
        toast.error('Invalid phone number!');
      }
    }
    await main_api
      .patch(`/admin/users/${actionUserId}/`, formData)
      .then((res) => {
        const userData = originUserData.find((item) => item.id === actionUserId);
        userData.name = name;
        userData.email = email;
        userData.phone = phone(phoneNumber).phoneNumber;
        onClose();
        setOriginUserData([...originUserData]);
        toast.success('User updated successfully!');
      })
      .catch((err) => {
        toast.error('Error!');
      });
  };

  const initFormData = () => {
    const userData = originUserData.find((item) => item.id === actionUserId);
    setName(userData.name);
    setEmail(userData.email);
    setPhoneNumber(userData.phone);
  };

  useEffect(() => {
    if (actionUserId) {
      initFormData();
    }
  }, [actionUserId]);

  return (
    <ModalWrapper show={show} onHide={onClose} centered>
      <ModalContentWrapper>
        <CloseModalBtn onClick={onClose}>
          <CloseIcon src={CloseSquareIcon} alt="close-icon" />
        </CloseModalBtn>
        <ModalFormWrapper onSubmit={handleSubmit}>
          <ModalTitle className="text-center">Edit User</ModalTitle>
          <ModalInput
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
          />
          <ModalInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <ModalInput
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number"
          />
          <NormalButton type="submit" content="Save changes" />
        </ModalFormWrapper>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export default EditUserModal;
