/* eslint-disable prettier/prettier */
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
// import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';

// const loggerMiddleware = createLogger();
const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(
    thunkMiddleware
    // loggerMiddleware
  )
);
export const appPersist = persistStore(store);
