/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ success }) =>
    success
      ? css`
          border: 1px solid green;
          color: green;
        `
      : css`
          border: 1px solid var(--error);
          color: var(--error);
        `}
`;
