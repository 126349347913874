import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import DeleteIcon from '../../assets/images/icons/delete-icon.svg';
import EditIcon from '../../assets/images/icons/edit-icon.svg';
import PhoneIcon from '../../assets/images/icons/phone-icon.svg';
import { avatarBgColors } from '../../config/user.config';
import NormalButton from '../buttons/NormalButton';
import StatusDropdown from '../dropdowns/StatusDropdown';
import {
  ActionButton,
  ActionButtonIcon,
  CustomColumn,
  StatusWrapper,
  TableContainer,
  UserAvatar,
  UserNameAvatar,
} from './Table.styled';

const UserTable = ({
  columns,
  data,
  page,
  setShowEditUserModal,
  handleShowDeleteUserModal,
  setActionUserId,
  approveUser,
}) => {
  const dynamicColumns = columns
    .filter((item) => item.field !== 'name')
    .map((col, i) => {
      return (
        <Column
          key={i}
          columnKey={col.field}
          field={col.field}
          header={col.header}
          headerStyle={{ background: 'none' }}
        />
      );
    });

  const nameTemplate = (data) => {
    const randomIndex = Math.floor(Math.random() * avatarBgColors.length);
    const backgroundColor = avatarBgColors[randomIndex];
    const fontColor = randomIndex < 5 ? 'white' : 'black';
    return (
      <CustomColumn className="d-flex align-items-center">
        {data.avatar ? (
          <UserAvatar src={data.avatar} alt="user-avatar" />
        ) : (
          <UserNameAvatar
            className="d-flex align-items-center justify-content-center"
            backgroundColor={data.name ? backgroundColor : '#000'}
            fontColor={data.name ? fontColor : '#fff'}
          >
            {data.name ? data.name[0] : 'N'}
          </UserNameAvatar>
        )}
        {data.name}
      </CustomColumn>
    );
  };

  const actionTemplate = (data) => {
    const handleEditUser = () => {
      setShowEditUserModal();
      setActionUserId(data.id);
    };

    const handleDeleteUser = () => {
      handleShowDeleteUserModal();
      setActionUserId(data.id);
    };

    return (
      <CustomColumn className="d-flex">
        <ActionButton>
          <ActionButtonIcon src={PhoneIcon} alt="phone-icon" />
        </ActionButton>
        <ActionButton onClick={handleEditUser}>
          <ActionButtonIcon src={EditIcon} alt="edit-icon" />
        </ActionButton>
        <ActionButton onClick={handleDeleteUser}>
          <ActionButtonIcon src={DeleteIcon} alt="delete-icon" />
        </ActionButton>
      </CustomColumn>
    );
  };

  const StatusTemplate = (data) => {
    const [status, setStatus] = useState('');
    useEffect(() => {
      setStatus(data.approved ? 'Approve' : 'Decline');
    }, [data]);

    useEffect(() => {
      if (status && (status === 'Approve' ? true : false) !== data.approved) {
        approveUser(data.id, status === 'Approve' ? true : false);
      }
    }, [status]);

    return (
      <StatusDropdown
        dropdownItems={['Approve', 'Decline']}
        activeDropdownItem={status}
        setActiveDropdownItem={setStatus}
      />
    );
  };

  const detailTemplate = (data) => {
    return (
      <NormalButton bordered height="36px" fontSize="16px" width="93px" content="See Details" />
    );
  };

  return (
    <TableContainer className={page}>
      <DataTable
        style={{ border: 'none' }}
        tableStyle={{ border: 'none' }}
        value={data}
        globalFilterFields={columns.map((item) => item.field)}
        emptyMessage="No users found."
        reorderableRows
        responsiveLayout="scroll"
        sortMode="single"
        sortField="name"
        editMode="row"
        sortOrder={1}
      >
        <Column field="name" header="Name" body={nameTemplate} />
        {dynamicColumns}
        {page === 'all-user' && <Column field="edit" header="Action" body={actionTemplate} />}
        {page === 'approve' && <Column field="status" header="" body={StatusTemplate} />}
        {page === 'approve' && <Column field="detail" header="" body={detailTemplate} />}
      </DataTable>
    </TableContainer>
  );
};

export default UserTable;
