import { Component } from 'react';
import { TrackJS } from 'trackjs';

const token = process.env.REACT_APP_TRACKJS_TOKEN;

if (process.env.NODE_ENV === 'production') {
  TrackJS.install({ token });
}

export default class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    if (errorInfo && errorInfo.componentStack) {
      console.log(errorInfo.componentStack);
    }

    if (process.env.NODE_ENV === 'production') {
      TrackJS.track(error);
    }
  }

  render() {
    return this.props.children;
  }
}
