import styled from 'styled-components';

export const TableContainer = styled.div`
  * {
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }
  .p-datatable-wrapper {
    table {
      tr {
        td,
        th {
          padding: 22px 0;
          border-color: #ededed;
          background-color: #fff;
          &:last-child {
            width: 128px;
          }
          &[colspan='5'] {
            text-align: center;
          }
        }
      }
    }
  }
`;

export const CustomColumn = styled.div``;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 18px;
`;

export const UserNameAvatar = styled.span`
  width: 40px;
  height: 40px;
  padding-right: 5px;
  border-radius: 50%;
  text-transform: uppercase;
  font-size: 24px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
  margin-right: 18px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width || '32px'};
  height: ${(props) => props.height || '32px'};
  background: ${(props) => props.background || '#ededed'};
  border-radius: 5px;
  &:not(:last-child) {
    margin-right: ${(props) => props.marginRight || '16px'};
  }
`;

export const ActionButtonIcon = styled.img`
  width: ${(props) => props.width || '16px'};
  height: ${(props) => props.height || '16px'};
`;

export const StatusWrapper = styled.div`
  width: 94px;
  padding: 8px 0;
  text-align: center;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  &.decline {
    background: #ededed;
    color: #000000;
    border-color: transparent;
  }
`;
