import styled from 'styled-components';

export const FeedPageContainer = styled.div`
  max-width: 1188px;
  padding-top: 55px;
  width: 100%;
`;

export const PageTitle = styled.h1`
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 24px;
`;

export const FeeItemWrapper = styled.div`
  width: 570px;
  height: 75px;
  border: 1.5px solid #000000;
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  color: #000000;
  padding: 18px 21px 18px 24px;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 26px;
  }
`;

export const FeeInput = styled.input`
  width: ${(props) => props.width || '86px'};
  height: 41px;
  border: 1.5px solid #000000;
  border-radius: 5px;
  font-weight: 400;
  font-size: 24px;
  line-height: 16px;
  color: #010101;
  text-align: center;
`;
