import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { main_api } from '../../api/auth';
import { userRoles } from '../../config/user.config';
import NormalButton from '../buttons/NormalButton';
import AddNewUserDropdown from '../dropdowns/AddNewUserDropdown';
import {
  ModalContentWrapper,
  ModalFormWrapper,
  ModalInput,
  ModalTitle,
  ModalWrapper,
} from './UserModal.styled';

const AddNewUserModal = ({ show, onClose, handleShowEmailVerifyModal, setOriginUserData }) => {
  const [activeDropdownItem, setActiveDropdownItem] = useState('');

  const handleAddUser = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append('type', activeDropdownItem);
    await main_api
      .post('/admin/users/create_user/', formData)
      .then((res) => {
        setOriginUserData((val) => [res.data, ...val]);
        onClose();
        handleShowEmailVerifyModal();
        toast.success('User added successfully');
      })
      .catch((err) => toast.error('User could not be added'));
  };

  return (
    <ModalWrapper show={show} onHide={onClose} centered>
      <ModalContentWrapper>
        <ModalFormWrapper onSubmit={handleAddUser}>
          <ModalTitle className="text-center">Add New User</ModalTitle>
          <ModalInput name="name" placeholder="Full Name" />
          <ModalInput name="email" placeholder="Email" />
          <AddNewUserDropdown
            dropdownItems={userRoles}
            activeDropdownItem={activeDropdownItem}
            setActiveDropdownItem={setActiveDropdownItem}
          />
          <NormalButton type="submit" content="Add User" />
        </ModalFormWrapper>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export default AddNewUserModal;
