/* eslint-disable prettier/prettier */
import React from 'react';
import { connect } from 'react-redux';
import Header from './header';
import pages from './pages';
import { Wrapper } from './styles';

const Main = (props) => {
  const [page, setPage] = React.useState(props.page || 'home');
  const Component = pages[page.id || page];

  return (
    <Wrapper>
      <Header setPage={setPage} />
      <Component setPage={setPage} {...page} page={page.id || page} />
    </Wrapper>
  );
};

export default connect()(Main);
