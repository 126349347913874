import React, { useEffect, useState } from 'react';
import { main_api } from '../api/auth';
import Pagination from '../components/Pagination';
import AddUserButton from '../components/buttons/AddUserButton';
import FilterButton from '../components/buttons/FilterButton';
import NormalButton from '../components/buttons/NormalButton';
import SearchInput from '../components/inputs/SearchInput';
import AddNewUserModal from '../components/modals/AddNewUserModal';
import EmailVerifyModal from '../components/modals/EmailVerifyModal';
import UserTable from '../components/tables/UserTable';
import { StatusButtons, tableColumns } from '../config/approvedAccounts.config';
import { UserData } from '../config/user.config';
import {
  ApprovedAccountsPageContainer,
  ButtonContainer,
  ButtonIcon,
  TopContainer,
} from './ApprovedAccounts.styled';

const ApprovedAccountsPage = () => {
  const [originUserData, setOriginUserData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [users, setUsers] = useState([]);
  const [activePaginationItem, setActivePaginationItem] = useState(1);
  const [pageShowUsers, setPageShowUsers] = useState(8);
  const [showAddNewUserModal, setShowAddNewUserModal] = useState(false);
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(StatusButtons[0].status);
  const [userSearchValue, setUserSearchValue] = useState('');

  const sortDate = (date1, date2) => {
    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    } else {
      return 0;
    }
  };

  const getAllUsers = async () => {
    const { data } = await main_api.get('/admin/users/');
    setOriginUserData([
      ...data
        .filter((item) => item.student || item.tutor)
        .sort((a, b) =>
          sortDate(
            new Date(b.student ? b.student.created_at : b.tutor.created_at),
            new Date(a.student ? a.student.created_at : a.tutor.created_at)
          )
        ),
    ]);
  };

  const approveUser = async (id, value) => {
    const data = originUserData.find((user) => user.id === id);
    data.approved = value;
    setOriginUserData([...originUserData]);
    const formData = new FormData();
    formData.append('approved', value);
    await main_api
      .patch(`/admin/users/${id}/`, formData)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setActivePaginationItem(1);
    switch (activeStatus) {
      case 'all':
        setUserData([...originUserData]);
        break;
      case 'approve':
        setUserData([...originUserData.filter((user) => user.approved === true)]);
        break;
      case 'decline':
        setUserData([...originUserData.filter((user) => user.approved === false)]);
        break;
      default:
        break;
    }
  }, [activeStatus]);

  useEffect(() => {
    setUsers([
      ...userData.slice(
        pageShowUsers * (activePaginationItem - 1),
        pageShowUsers * activePaginationItem
      ),
    ]);
  }, [activePaginationItem, userData]);

  useEffect(() => {
    setUserData([...originUserData]);
  }, [originUserData]);

  useEffect(() => {
    setUserData([
      ...originUserData.filter((user) =>
        (user.name + '').toLowerCase().includes(userSearchValue.toLowerCase())
      ),
    ]);
  }, [userSearchValue]);

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <ApprovedAccountsPageContainer>
      <TopContainer className="d-flex justify-content-between flex-fill">
        <SearchInput
          value={userSearchValue}
          onChange={(e) => setUserSearchValue(e.target.value)}
          maxWidth="825px"
        />
        <FilterButton />
        <AddUserButton onClick={() => setShowAddNewUserModal(true)} />
      </TopContainer>
      <ButtonContainer className="d-flex">
        {StatusButtons.map((item, index) => (
          <NormalButton
            content={
              <>
                {item.name}
                {item.icon ? (
                  <ButtonIcon
                    src={activeStatus === item.status ? item.activeIcon : item.icon}
                    alt="button-icon"
                  />
                ) : (
                  ''
                )}
              </>
            }
            padding={item.icon ? '16.5px 16px' : ''}
            width={item.icon ? 'auto' : '160px'}
            fontSize="18px"
            bordered={item.status !== activeStatus}
            marginRight={index < StatusButtons.length - 1 ? '16px' : ''}
            key={index}
            onClick={() => setActiveStatus(item.status)}
          />
        ))}
      </ButtonContainer>
      <UserTable data={users} columns={tableColumns} approveUser={approveUser} page="approve" />
      <Pagination
        paginationNumber={Math.ceil(userData.length / pageShowUsers)}
        activePaginationItem={activePaginationItem}
        setActivePaginationItem={setActivePaginationItem}
        pageShowUsers={pageShowUsers}
        maxShowUsers={userData.length}
      />
      <AddNewUserModal
        show={showAddNewUserModal}
        setShowEmailVerifyModal={setShowEmailVerifyModal}
        onClose={() => setShowAddNewUserModal(false)}
        setOriginUserData={setOriginUserData}
      />
      <EmailVerifyModal
        show={showEmailVerifyModal}
        onClose={() => setShowEmailVerifyModal(false)}
      />
    </ApprovedAccountsPageContainer>
  );
};

export default ApprovedAccountsPage;
