import React from 'react';
import { SideBar } from './styles';

const SidebarContent = ({ setPage, PAGES }) => {
  return (
    <SideBar>
      <div>
        <img className="img-logo" alt="" src="./static/img/flower.png" />
        <h4 className="title">Teacher's only Tutoring</h4>
      </div>
      <div className="content-container">
        {PAGES.map((ele, index) => {
          return (
            <span key={`${ele.id}-${index}`} onClick={() => setPage(ele.page)}>
              {ele.title}
            </span>
          );
        })}
      </div>
    </SideBar>
  );
};

export default SidebarContent;
