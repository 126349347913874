import React from 'react';
import VerifyEmailImage from '../../assets/images/verify-email-image.png';
import NormalButton from '../buttons/NormalButton';
import {
  ButtonWrapper,
  ModalContentWrapper,
  ModalDescription,
  ModalImage,
  ModalTitle,
  ModalWrapper,
} from './EmailVerifyModal.styled';

const EmailVerifyModal = ({ show, onClose }) => {
  return (
    <ModalWrapper show={show} onHide={onClose} centered>
      <ModalContentWrapper>
        <ModalImage src={VerifyEmailImage} alt="verify-modal-image" />
        <ModalTitle>Credential email sent</ModalTitle>
        <ModalDescription>
          Lorem Ipsum is simply dummy <strong>emailaddress@gmail.com.</strong> Lorem Ipsum has been
          the industry's standard dummy text ever since the 1500s, when an unknown printer took a
          galley of type and scrambled it to make a type specimen book.
        </ModalDescription>
        <ButtonWrapper>
          <NormalButton content="Close" marginBottom={'16px'} bordered onClick={onClose} />
        </ButtonWrapper>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export default EmailVerifyModal;
