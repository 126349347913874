import React from 'react';
import { useParams } from 'react-router-dom';
import { Container } from './styles';

const AboutUs = () => {
  const { link } = useParams();

  if (link) {
    window.location.href = `tot::/${link}`;
  }

  return (
    <>
      <Container>
        <h2 className="text-title">{`tot::/${link}` || 'TOT'}</h2>
      </Container>
    </>
  );
};

export default AboutUs;
