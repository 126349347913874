import styled from 'styled-components/macro';

export const MainWrapper = styled.div`
  svg {
    width: 40px;
    position: absolute;
    margin: 10px;
    padding: 5px;
    background: #ffffff50;
    color: #141619;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0px 0px 10px #00000050;
    z-index: 9999;
  }

  @media (min-width: 601px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #fff;
`;

export const ProfileWrapper = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: scroll;
`;

export const BottomWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

export const LogoutWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  align-items: center;

  div {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
  }
`;
