/* eslint-disable prettier/prettier */
import { useDispatch, useSelector } from 'react-redux';

const useHooks = () => {
  const { loading, userInfo } = useSelector((state) => state.SharedStore);

  const dispatch = useDispatch();
  const isLoading = (value) => {
    dispatch({
      type: 'LOADING',
      payload: value,
    });
  };
  return {
    loading,
    isLoading,
    userInfo,
  };
};

export default useHooks;
