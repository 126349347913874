import styled, { css, keyframes } from 'styled-components';

const ANI_SPEED = 1000;

const ani = keyframes`
  0% {
    opacity: 0.3; }
  100% {
    opacity: 0.05; }
`;

const Wrapper = styled.div`
  ${({ relative, w }) =>
    relative
      ? css`
          position: relative;
        `
      : css`
          width: 100%;
          height: 100%;
          background: #00000029;
          position: absolute;
        `}

  color: #fff;
  z-index: 999999999;
  background: #00000060;
`;

const Item = styled.div`
  position: absolute;
  background-color: currentColor;
  animation: ${ani} ${ANI_SPEED}ms linear infinite;

  ${({ i, w, h }) => css`
    top: calc(50% - ${h / 2}px);
    left: calc(50% - ${w / 2}px);
    width: ${w}px;
    height: ${h}px;
    border-radius: ${w / 2}px;
    transform: rotate(${i * 45}deg) translateY(${h}px);
    animation-delay: ${-ANI_SPEED + (ANI_SPEED / 8) * i}ms;
  `}
`;

const Loader = ({ size, ...props }) => {
  const w = size === 'small' ? 2 : 4;
  const h = Math.floor(w * 2.5);
  return (
    <Wrapper w={h * 3} {...props}>
      {Array.from({ length: 8 }).map((_, i) => (
        <Item key={i} i={i} w={w} h={h} />
      ))}
    </Wrapper>
  );
};

export default Loader;
