import React from 'react';
import SearchIcon from '../../assets/images/icons/search-icon.svg';
import { CustomInput, InputIcon, SearchContainer } from './Input.styled';

const SearchInput = ({ maxWidth, value, onChange }) => {
  return (
    <SearchContainer className="flex-fill" maxWidth={maxWidth}>
      <InputIcon src={SearchIcon} alt="search" />
      <CustomInput placeholder="Search" value={value} onChange={onChange} />
    </SearchContainer>
  );
};

export default SearchInput;
