import styled from 'styled-components';

export const ReportPageContainer = styled.div`
  max-width: 1188px;
  width: 100%;
`;

export const BtnPanel = styled.div`
  margin-top: 42px;
  margin-bottom: 18px;
`;
