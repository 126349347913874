import React from 'react';
import { Dropdown } from 'react-bootstrap';
import ArrowDownIcon from '../../assets/images/icons/arrow-down-icon.svg';
import { DropdownButton, DropdownIcon, DropdownItem, DropdownMenu } from './Dropdown.styled';

const StatusDropdown = ({ dropdownItems, activeDropdownItem, setActiveDropdownItem }) => {
  return (
    <Dropdown>
      <DropdownButton
        className={
          'd-flex align-items-center justify-content-center status-dropdown ' +
          (activeDropdownItem === 'Approve' ? 'dark-mode' : '')
        }
      >
        {activeDropdownItem}
      </DropdownButton>
      <DropdownMenu className="status-dropdown-menu">
        {dropdownItems.map((item, index) => {
          return (
            <DropdownItem key={index} onClick={() => setActiveDropdownItem(item)} height="36px">
              {item}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default StatusDropdown;
