import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .modal-dialog {
    max-width: 580px;
    width: 100%;
    margin: auto;
    height: 100vh;
    padding: 20px;
    .modal-content {
      position: relative;
      border-radius: 0;
    }
  }
`;

export const ModalContentWrapper = styled(Modal.Body)`
  padding: 65px 95px;
`;

export const CloseModalBtn = styled.button`
  position: absolute;
  top: 32px;
  right: 32px;
  width: 24px;
  height: 24px;
  background-color: transparent;
`;

export const CloseIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const ModalTitle = styled.h2`
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 50px;
`;

export const ModalInput = styled.input`
  width: 100%;
  height: 56px;
  padding: 18px 16px;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  border: 1.5px solid #000000;
  border-radius: 5px;
  margin-bottom: 24px;
  outline: 0;
  &::placeholder {
    color: #000000;
  }
`;

export const DeleteUserImageWrapper = styled.div`
  margin-top: 87px;
  margin-bottom: 72px;
`;

export const DeleteUserImg = styled.img`
  width: 211px;
  height: 155px;
  margin: auto;
`;

export const ModalFormWrapper = styled.form``;
