import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { TimesSvg } from '@svgs';
import { Wrapper, Overlay, CloseButton } from './styles';

const Modal = ({
  component: Component,
  visible,
  hideCloseButton,
  animation = 'fade',
  overlayStyle,
  closeButtonStyle,
  style,
  onClose,
  ...props
}) => {
  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };
  return (
    <>
      {visible && <Overlay onClick={handleClose} style={overlayStyle} />}
      <CSSTransition in={visible} unmountOnExit timeout={500}>
        <Wrapper animation={animation} style={style}>
          <Component {...props} onClose={onClose} />
          {!hideCloseButton && (
            <CloseButton onClick={handleClose} style={closeButtonStyle}>
              <TimesSvg />
            </CloseButton>
          )}
        </Wrapper>
      </CSSTransition>
    </>
  );
};

const createModal = (props) => {
  return ReactDOM.createPortal(<Modal {...props} />, document.getElementById('modal'));
};

export default createModal;
