import React, { useState } from 'react';
import { Bottom, Container, Header } from './styles';

const axios = require('axios');

const ContactUs = () => {
  const [userInput, setUserInput] = useState({});

  const onChange = (e) => {
    setUserInput({ ...userInput, [e.target.name]: e.target.value });
  };

  const submit = () => {
    console.log(userInput);

    const config = {
      method: 'POST',
      url: 'https://purple-disk-37273.botics.co/api/v1/landing_request/',
      data: userInput,
    };

    axiosCall(config);
  };

  const axiosCall = async (config) => {
    return await axios(config)
      .then((response) => {
        console.log({ success: response.data });
      })
      .catch(async (error) => {
        alert(JSON.stringify(error));
      });
  };
  return (
    <>
      <Container>
        <Header>
          <div className="contact-help">
            <h2 class="text-title">Contact Us For Help</h2>
            {/* <div className="text-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut <br />
              labore et dolore magna aliqua. Ut enim ad minim veniam
            </div> */}
          </div>
        </Header>
        <Bottom>
          <div className="two-fields">
            <div className="phone-number-box">
              <label htmlFor="phone">Phone Number</label>
              <input type="number" name="Phone" className="phone" onChange={onChange} />
            </div>
            <div className="email-box">
              <label htmlFor="email">E-mail</label>
              <input type="email" name="email" className="email" onChange={onChange} />
            </div>
          </div>
          <div className="single-field">
            <div className="desc-div">
              <label htmlFor="description">Enter Description </label>
              <br />
              <textarea
                className="description"
                name="description"
                cols="100"
                rows="7"
                onChange={onChange}
              ></textarea>
            </div>
          </div>
          <div className="btn-box">
            <button type="button" className="send-btn" onClick={submit}>
              Send
            </button>
          </div>
        </Bottom>
      </Container>
    </>
  );
};
export default ContactUs;
