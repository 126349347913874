import React from 'react';
import {
  PaginationContainer,
  PaginationItem,
  PaginationText,
  PaginationWrapper,
} from './Pagination.styled';

const Pagination = ({
  paginationNumber,
  activePaginationItem,
  setActivePaginationItem,
  pageShowUsers,
  maxShowUsers,
}) => {
  const numbers = Array.from({ length: paginationNumber }, (v, i) => i + 1);

  return (
    <PaginationContainer className="d-flex align-items-center justify-content-between">
      <PaginationText>
        Showing{' '}
        {pageShowUsers * (activePaginationItem - 1) +
          1 +
          ' to ' +
          (pageShowUsers * activePaginationItem <= maxShowUsers
            ? pageShowUsers * activePaginationItem
            : maxShowUsers) +
          ' of ' +
          maxShowUsers +
          ' entries'}
      </PaginationText>
      <PaginationWrapper className="d-flex">
        {numbers.map((item, index) => (
          <PaginationItem
            key={index}
            className={activePaginationItem === item ? 'active' : ''}
            onClick={() => setActivePaginationItem(item)}
          >
            {item}
          </PaginationItem>
        ))}
      </PaginationWrapper>
    </PaginationContainer>
  );
};

export default Pagination;
