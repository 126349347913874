import React from 'react';
import AddIcon from '../../assets/images/icons/add-icon.svg';
import { AddUserBtn, BtnIcon } from './Button.styled';

const AddUserButton = ({ onClick }) => {
  return (
    <AddUserBtn className="d-flex align-items-center justify-content-center" onClick={onClick}>
      Add User
      <BtnIcon src={AddIcon} width={'28px'} height={'28px'} marginLeft={'16px'} alt="add-icon" />
    </AddUserBtn>
  );
};

export default AddUserButton;
