/* eslint-disable prettier/prettier */
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 1100px;
  margin: 0 auto;
  height: 100vh;
  oveflow-x: hidden;
  background: #ffffff;
  overflow: scroll;

  @media (min-width: 481px) and (max-width: 767px) {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  ul li {
    list-style-type: none;
    display: inline-block;
    padding: 20px;
  }
  a {
    width: 30px;
    height: 44px;
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 44px;
    color: #000000;
    text-decoration: none;
  }
  span {
    width: 30px;
    height: 44px;
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 44px;
    color: #000000;
    cursor: pointer;
  }
  .log-img {
    margin-right: 300px;

    img {
      width: 100px;
    }
  }

  .title {
    font-size: 10px;
    text-align: center;
    font-family: 'Caveat', cursive;
  }
`;

export const BottomParent = styled.div`
  display: flex;
  @media (min-width: 481px) and (max-width: 767px) {
    display: grid;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    display: grid;
  }
`;
export const ChildLeft = styled.div`
  width: 50%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: #010101;
    font-size: 80px;
    font-family: 'Caveat', cursive;
    padding: 100px 0 0;
    line-height: 1em;
  }
  h5 {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px
    padding: 10px 50px 0px 50px;
  }
  .btn {
    padding: 0;
    margin: 20px 0;
  }
  .btn button {
    padding: 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    height: auto;
    h5 {
      font-family: 'Caveat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      padding: 0 20px;
    }
    .btn {
      display: block;
      padding: 0px;
    }
    h3 {
      font-size: 60px;
      padding: 0;
      text-align: center;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    h5 {
      font-family: 'Caveat';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      padding: 0 20px;
    }
    .btn {
      display: block;
      padding: 0px;
    }
    h3 {
      font-size: 60px;
      padding: 100px 0px 0px 0px;
      text-align: center;
    }
  }
`;
export const ChildRight = styled.div`
  width: 50%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 500px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;

export const AboutHead = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .about-text {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 30px;
    text-align: center;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    position: relative;
    bottom: 100px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    position: relative;
    bottom: 100px;
  }
`;

export const AboutParent = styled.div`
  display: flex;
  @media (min-width: 481px) and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2;
    text-align: center;
  }
  @media (max-width: 480px) {
    display: grid;
    width: 100%;
  }
`;
export const AboutLeft = styled.div`
  width: 50%;
  height: 700px;
  .bg-tem {
    padding: 150px;
    background-color: #5e5e4c;
    border-radius: 0px 20px 20px 0px;
    position: relative;
    top: 280px;
  }
  .iphone-img {
    position: relative;
    bottom: 320px;
    left: 100px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    height: 500px;
    .bg-tem {
      width: 100%;
      top: 0px;
      border-radius: 0px;
    }
    .iphone-img {
      width: 100%;
      bottom: 490px;
      left: 48%;
      transform: translateX(-50%);
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: 500px;
    .bg-tem {
      width: 100%;
      top: 0px;
      border-radius: 0px;
    }
    .iphone-img {
      width: 100%;
      bottom: 490px;
      left: 40%;
      transform: translateX(-50%);
    }
  }
`;
export const AboutRight = styled.div`
  width: 50%;
  height: 700px;

  .h2-text {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    padding: 0 20px;
  }
  .para1 {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    padding: 0 20px;
  }

  .para2 {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    padding: 20px;
  }
  .both-btn {
    padding: 0 70px;
    position: relative;
    bottom: 35px;
  }
  .watch-btn {
    width: 150px;
    height: 50px;
    background-color: black;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-family: 'Caveat', cursive;
  }
  .view-btn {
    width: 150px;
    height: 50px;
    background-color: transparent;
    border-radius: 10px;
    margin-left: 20px;
    cursor: pointer;
    font-family: 'Caveat', cursive;
    border: 2px solid black;
  }
  .pad_text {
    padding: 50px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    height: auto;
    .pad_text {
      padding: 0px;
    }
    .para1 {
      margin: 0;
    }
    .para2 {
      margin: 0;
    }
    .both-btn {
      padding: 0;
      text-align: center;
      position: relative;
      bottom: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: 500px;
    .pad_text {
      padding: 0px;
    }
    .para1 {
      margin: 0;
      width: 390px;
    }
    .para2 {
      margin: 0;
      width: 390px;
    }
    .both-btn {
      padding: 0;
      text-align: center;
      position: relative;
      bottom: 0px;
    }
  }
`;
export const FamilyFAQS = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .family-FAQ-text {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    .family-FAQ-text {
      font-size: 60px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: auto;
    margin-top: 150px;
    margin-bottom: 50px;
    .family-FAQ-text {
      font-size: 60px;
    }
  }
`;
export const FAQSParent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (min-width: 481px) and (max-width: 767px) {
    display: table;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    display: table;
  }
`;
export const FAQContainer = styled.div`
  background: ${({ isSelected }) => (isSelected ? '#fff' : '#f5f5f5')};
  box-shadow: ${({ isSelected }) => isSelected && '0px 0px 50px rgba(0, 0, 0, 0.05)'};
  width: 730px;

  .question-wrapper {
    width: 730px;
    height: 80px;
    box-shadow: black;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background: black;
    background: ${({ isSelected }) => (isSelected ? 'red' : 'black')};
    margin-left: 20px;
    cursor: pointer;
  }

  .question-text {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    padding-left: 10px;
  }

  .answer {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    padding: 20px 20px 20px 60px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    .answer {
      padding: 20px 20px 20px 20px;
    }
    .question-wrapper {
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    .answer {
      padding: 20px 20px 20px 20px;
    }
    .question-wrapper {
      width: 100%;
    }
  }
`;

export const TutorFAQSHead = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .Tutor-FAQ-text {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    .Tutor-FAQ-text {
      font-size: 60px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    .Tutor-FAQ-text {
      font-size: 60px;
    }
  }
`;
export const TutorFAQS = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .first {
    width: 730px;
    height: 80px;
    box-shadow: black;
    background: #f5f5f5;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .first-btn {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background: black;
    margin-left: 20px;
    cursor: pointer;
  }
  .second {
    width: 730px;
    height: 172px;
    background: #ffffff;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  .second-btn {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    background: red;
    margin-left: 20px;
    cursor: pointer;
    border: none;
  }
  .btn-h4 {
    display: flex;
    align-items: center;
    padding: 20px 0px 0px 0px;
  }

  .second-text {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    padding-left: 10px;
  }

  .second-para {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    padding: 20px 20px 20px 60px;
  }
`;
export const DownAppHead = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  .download-text {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    height: auto;
    margin-top: 50px;
    .download-text {
      line-height: 75px;
      font-size: 60px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    .download-text {
      line-height: 75px;
      font-size: 60px;
    }
  }
`;
export const DownParent = styled.div`
  display: flex;
  @media (min-width: 481px) and (max-width: 767px) {
    display: grid;
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    display: grid;
    width: 100%;
  }
`;
export const DownLeft = styled.div`
  width: 50%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .heading-left {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    color: #000000;
    padding: 0 50px;
  }
  .left-para {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    padding: 0 50px;
  }

  .btn {
    padding: 10px 40px;
    text-align: left;
  }

  .google-btn-download {
    background: none;
    border: none;
    cursor: pointer;
  }

  .mac-btn-download {
    background: none;
    border: none;
    cursor: pointer;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    height: auto;
    position: relative;
    width: 100%;
    .heading-left {
      padding: 0px 30px 0px 20px;
      text-align: center;
      font-size: 40px;
    }
    .left-para {
      padding-left: 20px;
      width: 95%;
      text-align: justify;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    height: 500px;
    position: relative;
    top: -160px;
    width: 100%;
    .heading-left {
      padding: 0;
      text-align: center;
      font-size: 40px;
      width: 390px;
      margin-top: 100px;
    }
    .left-para {
      padding-left: 20px;
      width: 390px;
      padding: 20px;
      text-align: justify;
    }
    .btn {
      width: 390px;
    }
  }
`;
export const DownRight = styled.div`
  width: 50%;
  height: 700px;

  .bg-iphone {
    position: relative;
    top: 300px;
    padding: 150px;
    background: #010101;
    border-radius: 20px 0px 0px 20px;
  }

  .iphone-image {
    position: relative;
    bottom: 320px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
    .iphone-image {
      left: 48%;
      transform: translateX(-50%);
      bottom: 340px;
    }
    .bg-iphone {
      position: relative;
      top: 200px;
      padding: 150px;
      background: #010101;
      border-radius: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    height: 580px;
    width: 100%;
    .bg-iphone {
      position: relative;
      top: 0px;
      padding: 150px;
      background: #010101;
      border-radius: 0px;
    }
    .iphone-image {
      bottom: 470px;
      left: 40%;
      transform: translateX(-50%);
    }
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  .box-1 {
    flex-direction: column;
    width: 50%;
  }
  .box-2 {
    flex: 1;
    width: 50%;
  }
  .background-input {
    width: 100%;
    height: 550px;
    background-color: #d7d7cc;
    border-radius: 5px;
  }

  .div-contact {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-us {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 30px;
    color: black;
    text-align: center;
    padding: 20px;
  }

  .div-para {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-para {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: black;
    padding: 0px 289px 0px 230px;
  }

  .input-div {
    margin: 0 auto;
    width: 50%;
  }
  label {
    color: black;
    font-size: 20px;
    font-family: 'Caveat', cursive;
  }

  .first-two-input {
    gap: 10px;
    display: flex;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .mobile-one {
    background: none;
    border: 1px solid #5e5e4c;
    border-radius: 5px;
    outline: none;
    height: 40px;
    margin-top: 5px;
    color: black;
    font-size: 20px;
    width: 100%;
  }

  .mobile-two {
    background: none;
    border: 1px solid #5e5e4c;
    border-radius: 5px;
    outline: none;
    height: 40px;
    margin-top: 5px;
    color: black;
    font-size: 20px;
    width: 100%;
  }

  .mobile-three {
    height: 40px;
    background: none;
    border: 1px solid #5e5e4c;
    border-radius: 5px;
    outline: none;
    color: black;
    font-size: 20px;
    margin-top: 5px;
    width: 100%;
  }
  .div-mobile {
    margin-top: 20px;
  }

  .check-agree {
    display: flex;
    align-items: center;
    padding: 20px 0;
  }

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    background: #fff;
  }
  .agree {
    font-family: 'Caveat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: black;
    padding-left: 10px;
    margin: 0;
  }

  .submit-btn {
    background-color: #5e5e4c;
    color: white;
    width: 140px;
    height: 45px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    margin-top: -20px;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Caveat', cursive;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .input-para {
      padding: 20px;
    }
    .first-two-input {
      flex-direction: column;
    }
    .div-mobile {
      padding: 20px;
      margin-top: 0;
    }
    .check-agree {
      padding: 20px 0;
    }
    .input-div {
      width: 100%;
      height: 350px;
    }
    .mobile-one {
      width: 100%;
    }
    .mobile-two {
      width: 100%;
    }
    .mobile-three {
      width: 100%;
    }
    input[type='checkbox'] {
      width: 10px;
      height: 10px;
    }
    .background-input {
      width: 100%;
      height: 780px;
      background-color: black;
      border-radius: 5px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    display: grid;
    width: 100%;
    height: 600px;
    .first-two-input {
      display: grid;
    }
    .div-para {
      width: 100%;
    }
    .input-para {
      padding: 20px;
    }
    .input-div {
      width: 100%;
    }
    .div-contact {
      display: grid;
      justify-content: center;
    }
    .mobile-one {
      width: 100%;
    }
    .mobile-two {
      width: 100%;
    }
    .mobile-three {
      width: 100%;
    }
    .background-input {
      width: 100%;
      height: 700px;
    }
    .div-mobile {
      width: 95%;
    }
  }
`;
export const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  .a-btn-download {
    background: none;
    border: none;
    cursor: pointer;
    width: 150px;
    height: 70px;
    border: 1px solid #000000;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      // flex-direction: column;
      // margin-top: 150px;
      width: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 150px;
    width: 100%;
  }
`;
