import styled from 'styled-components';

export const UploadPageWrapper = styled.div`
  max-width: 1260px;
  padding-top: 52px;
`;

export const UploadWrapper = styled.div`
  width: 540px;
  &:not(:last-child) {
    margin-right: 82px;
  }
`;

export const UploadPageTitle = styled.h1`
  font-size: 30px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 20px;
`;

export const UploadingPanel = styled.div`
  margin-top: 48px;
`;

export const UploadedItemPanel = styled.div`
  margin-top: 26px;
`;

export const UploadingItemBox = styled.div`
  position: relative;
  padding: 16px;
  height: 56px;
  background: #ededed;
  border: 1.5px solid #000000;
  border-radius: 5px;
  overflow: hidden;
`;

export const UploadProgressWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  .progress {
    height: 5px;
    .progress-bar {
      background-color: #000;
      border-radius: 0 5px 5px 0;
    }
  }
`;

export const UploadItemTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`;

export const UploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #ededed;
  border-radius: 5px;
  height: 56px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const UploadCheckPanel = styled.div`
  margin-top: 25px;
`;

export const UploadCheckWrapper = styled.label`
  &:not(:last-child) {
    margin-right: 32px;
  }
`;

export const UploadCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 19px;
  height: 19px;
  border: 1.5px solid #000000;
  border-radius: 5px;
  margin-right: 8px;
`;

export const UploadItemRightWrapper = styled.div``;

export const UploadItemButton = styled.button`
  margin-left: ${(props) => props.marginLeft || '0'};
`;

export const UploadItemIcon = styled.img`
  width: ${(props) => props.width || '16px'};
  height: ${(props) => props.height || '16px'};
`;
