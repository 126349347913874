import styled from 'styled-components/macro';

export const Header = styled.div`
  width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .log-img {
    width: 150px;
  }

  .title {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    font-family: 'Caveat', cursive;
  }

  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 0 10px;

    span {
      font-family: 'Caveat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 44px;
      color: #000000;
      cursor: pointer;
    }
    span:hover {
      border-bottom: 2px solid;
    }
  }

  @media (max-width: 600px) {
    display: none;
  }
`;
