import React, { useEffect, useState } from 'react';
import { main_api } from '../api/auth';
import Pagination from '../components/Pagination';
import AddUserButton from '../components/buttons/AddUserButton';
import UserFilterDropdown from '../components/dropdowns/UserFilterDropdown';
import SearchInput from '../components/inputs/SearchInput';
import AddNewUserModal from '../components/modals/AddNewUserModal';
import DeleteUserModal from '../components/modals/DeleteUserModal';
import EditUserModal from '../components/modals/EditUserModal';
import EmailVerifyModal from '../components/modals/EmailVerifyModal';
import UserTable from '../components/tables/UserTable';
import { tableColumns, userRoles } from '../config/user.config';
import {
  InputWrapper,
  ListAllUserPageContainer,
  PageTitle,
  TopContainer,
} from './ListAllUsers.styled';

const ListAllUserPage = () => {
  const [originUserData, setOriginUserData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [users, setUsers] = useState([]);
  const [activePaginationItem, setActivePaginationItem] = useState(1);
  const [pageShowUsers, setPageShowUsers] = useState(8);
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  const [showAddNewUserModal, setShowAddNewUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [activeDropdownItem, setActiveDropdownItem] = useState('');
  const [actionUserId, setActionUserId] = useState(null);
  const [userSearchValue, setUserSearchValue] = useState('');

  const sortDate = (date1, date2) => {
    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    } else {
      return 0;
    }
  };

  const getAllUsers = async () => {
    const { data } = await main_api.get('/admin/users/');
    setOriginUserData([
      ...data
        .filter((item) => item.student || item.tutor)
        .sort((a, b) =>
          sortDate(
            new Date(b.student ? b.student.created_at : b.tutor.created_at),
            new Date(a.student ? a.student.created_at : a.tutor.created_at)
          )
        ),
    ]);
  };

  const filterUsers = () => {
    if (activeDropdownItem) {
      setUserData([...originUserData.filter((item) => item.type === activeDropdownItem)]);
      setActivePaginationItem(1);
    }
  };

  const displayUsers = () => {
    setUsers([
      ...userData.slice(
        pageShowUsers * (activePaginationItem - 1),
        pageShowUsers * activePaginationItem
      ),
    ]);
  };

  useEffect(() => {
    displayUsers();
  }, [activePaginationItem, userData]);

  useEffect(() => {
    filterUsers();
  }, [activeDropdownItem]);

  useEffect(() => {
    setUserData([...originUserData]);
  }, [originUserData]);

  useEffect(() => {
    setUserData([
      ...originUserData.filter((user) =>
        (user.name + '').toLowerCase().includes(userSearchValue.toLowerCase())
      ),
    ]);
  }, [userSearchValue]);

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <ListAllUserPageContainer>
      <TopContainer className="d-flex justify-content-between flex-fill">
        <InputWrapper className="d-flex flex-fill">
          <SearchInput
            value={userSearchValue}
            onChange={(e) => setUserSearchValue(e.target.value)}
          />
          <UserFilterDropdown
            roles={userRoles}
            activeDropdownItem={activeDropdownItem}
            setActiveDropdownItem={setActiveDropdownItem}
          />
        </InputWrapper>
        <AddUserButton onClick={() => setShowAddNewUserModal(true)} />
      </TopContainer>
      <PageTitle>List of all users</PageTitle>
      <UserTable
        data={users}
        columns={tableColumns}
        setShowEditUserModal={() => setShowEditUserModal(true)}
        handleShowDeleteUserModal={() => setShowDeleteUserModal(true)}
        setActionUserId={setActionUserId}
        page="all-user"
      />
      <Pagination
        paginationNumber={Math.ceil(userData.length / pageShowUsers)}
        activePaginationItem={activePaginationItem}
        setActivePaginationItem={setActivePaginationItem}
        pageShowUsers={pageShowUsers}
        maxShowUsers={userData.length}
      />
      <AddNewUserModal
        show={showAddNewUserModal}
        onClose={() => setShowAddNewUserModal(false)}
        handleShowEmailVerifyModal={() => setShowEmailVerifyModal(true)}
        setOriginUserData={setOriginUserData}
      />
      <EditUserModal
        originUserData={originUserData}
        setOriginUserData={setOriginUserData}
        actionUserId={actionUserId}
        show={showEditUserModal}
        onClose={() => setShowEditUserModal(false)}
      />
      <DeleteUserModal
        show={showDeleteUserModal}
        onClose={() => setShowDeleteUserModal(false)}
        actionUserId={actionUserId}
        setOriginUserData={setOriginUserData}
      />
      <EmailVerifyModal
        show={showEmailVerifyModal}
        onClose={() => setShowEmailVerifyModal(false)}
      />
    </ListAllUserPageContainer>
  );
};

export default ListAllUserPage;
