/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import {
  Wrapper,
  BottomParent,
  ChildLeft,
  ChildRight,
  AboutParent,
  AboutLeft,
  AboutRight,
  FAQSParent,
  AboutHead,
  FamilyFAQS,
  DownAppHead,
  TutorFAQSHead,
  DownParent,
  DownLeft,
  DownRight,
  Footer,
  FAQContainer,
  Links,
} from './styles';

const FAQ_FAMIY = [
  {
    question: 'Tutor Hours & Days',
    answer:
      'Once you have selected what time/day of the week that works best for your schedule, it will show recommendations in your area that match your needs. Tutoring sessions can be recurring or can be scheduled as a one time session.',
  },
  {
    question: 'Payments',
    answer:
      'There will be a page on the application that stores all of your credit card information. The tutoring sessions will be charged automatically to your credit card after the tutoring session is complete. You will be able to view statements from every tutoring session that has been completed.',
  },
  {
    question: 'Scheduling and Virtual Calendar',
    answer:
      'All scheduling is done through a virtual calendar. If there is a specific day that you are looking for, you can click on that day and see the tutors available in your area.',
  },
  {
    question: 'Direct Messaging',
    answer:
      'Once you find a tutor that fits your needs, you will get the option to send the tutor a direct message. If the tutor accepts, they may ask more in depth questions about your child before entering the first tutoring session. This will help ensure that all of your child’s needs are met.',
  },
  {
    question: 'School Records Release',
    answer:
      'By signing this form, the tutor will be able to contact the school for your child’s records. They will be able to access Transcripts, Report Cards, State Testing, and any other information that will help your child be successful. This is optional, but if the tutor is given the permission to access these records, the tutor will have a better insight into the specific area/areas that your child is struggling with.',
  },
  {
    question: 'Reviews & Ratings',
    answer:
      'After a tutoring session is complete, families will be given the option to rate the tutor. The tutor’s should be rated on whether or not they came prepared and how much they helped your child that day. Parents should not expect their child’s grades to improve after one tutoring session.',
  },
  {
    question: 'Resources to help your child at home',
    answer:
      'This resource page will give you an insight on different activities to work on with your child at home. Tutors will create activities and videos to give you a better understanding of how to help your struggling student. If there is a specific skill that your child needs to work on, you will be able to search for activities related to this topic. If you do not see what you are looking for, you can send in your question, and a tutor will respond with different activities that can be done at home to master that skill.',
  },
];

const FAQ_TUTOR = [
  {
    question: 'Tutor Hours & Days',
    answer: `
    Tutors can choose the day of the week/times that work best for their schedule. There is no required amount of hours. Tutors can work as much or as little as they want each week.`,
  },
  {
    question: 'Where do you tutor?',
    answer: `Tutor wherever you feel comfortable. Tutors will have the option to select the family’s home, their own home, the local library, or virtually to complete tutoring sessions.
    `,
  },
  {
    question: 'Payments',
    answer:
      'Tutors will be asked to upload their banking information. Once that is set up, tutors will be able to transfer their money directly to their bank account. They will have access to statements from each tutoring session.',
  },
  {
    question: 'Direct Messaging',
    answer:
      'You will be able to message parents, other teachers, and the owner through direct messaging on the app. Families will not get your personal information unless you give it to them directly.',
  },
  {
    question: 'Reviews',
    answer: `You can leave reviews about the families that they are not able to see. This is private and for the tutors only. Tutors will be able to say if the parents are on time, or frequently cancel last minute. This will help other tutors decide if they want to fill that family's tutoring request.`,
  },
  {
    question: 'Tutoring Resources',
    answer: `
    This is a collaborative page for tutors with materials, lessons, and activities. Tutors can search the grade and subject to find activities to help with the student they are tutoring. If you have never tutored before and are not sure where to begin, you can watch an example video of what a tutoring session may look like. Tutors will also be able to upload their own lessons/videos that have worked well for them. With this collaboration page, tutors will have easy access to activities to work on during their tutoring sessions.`,
  },
  // {
  //   question: 'Tutoring Supplies',
  //   answer:
  //     'As an employee, you can join the Amazon business prime. Once you have signed up, you will be able to add your email address to the business account. After you are approved, you can buy tutoring materials at a discounted rate, and have them shipped directly to your home.',
  // },
];

const Home = ({ setPage, page }) => {
  const [openFAQ, setOpenFAQ] = useState();
  const [openFAQTutor, setOpenFAQTutor] = useState();
  return (
    <>
      <Wrapper>
        <BottomParent>
          <ChildLeft>
            <div>
              <h3>
                Finding Tutor
                <br />
                on demand & <br />
                fast
              </h3>
              <h5 style={{ fontFamily: 'Roboto' }}>
                The Teachers Only Tutoring app is a fast and simple way to find the perfect tutor in
                your area. If you have a child in K-12 schools that is struggling, this is a great
                option for you. Scheduling a tutoring session is made quick and easy by completing a
                short questionnaire about your child, and making an appointment on the virtual
                calendar. Download the Teachers Only Tutoring app to find tutors in your area today.
              </h5>
              <div className="btn">
                <a
                  target="_"
                  href="http://play.google.com/store/apps/details?id=com.google.android.apps.maps"
                  className="google-btn-download"
                >
                  <img alt="" src="./static/img/google-btn.png" style={{ width: '150px' }} />
                </a>
                <a
                  target="_"
                  href="https://apps.apple.com/us/app/google-maps/id585027354"
                  className="mac-btn-download"
                >
                  <img alt="" src="./static/img/mac-btn.png" style={{ width: '150px' }} />
                </a>
              </div>
            </div>
          </ChildLeft>
          <ChildRight>
            <img alt="" src="./static/img/art.png" />
          </ChildRight>
        </BottomParent>
        <AboutHead>
          <h2 className="about-text">About us</h2>
        </AboutHead>
        <AboutParent>
          <AboutLeft>
            <div className="bg-tem"></div>
            <div className="iphone-img">
              <img alt="" src="./static/img/iphone.png" />
            </div>
          </AboutLeft>
          <AboutRight>
            <div className="pad_text">
              <h2 className="h2-text">
                A Leading Tutoring Company
                {/* <br /> enim ad minim veniam */}
                <hr style={{ 'border-bottom': '2px solid #000', opacity: '1' }} />
              </h2>

              <p style={{ fontFamily: 'Roboto' }} className="para1">
                The Teachers Only Tutoring app hires licensed teachers in your area to help meet the
                goals of your child. As a parent, you will create a profile for your child listing
                the days, times, subject, and where you want the tutoring session to occur.
              </p>
              <p style={{ fontFamily: 'Roboto' }} className="para1">
                The Teachers Only Tutoring app hires licensed teachers in your area to help meet the
                goals of your child. As a parent, you will create a profile for your child listing
                the days, times, subject, and where you want the tutoring session to occur.
              </p>
            </div>
            <div className="both-btn">
              {/* <button className="watch-btn">Watch now</button>
              <button className="view-btn">View All </button> */}
              <a
                target="_"
                href="http://play.google.com/store/apps/details?id=com.google.android.apps.maps"
                className="google-btn-download"
              >
                <img alt="" src="./static/img/google-btn.png" style={{ width: '150px' }} />
              </a>
              <a
                target="_"
                href="https://apps.apple.com/us/app/google-maps/id585027354"
                className="mac-btn-download"
              >
                <img alt="" src="./static/img/mac-btn.png" style={{ width: '150px' }} />
              </a>
            </div>
          </AboutRight>
        </AboutParent>
        <FamilyFAQS>
          <h3 className="family-FAQ-text">Family Facts & Tutor Facts</h3>
        </FamilyFAQS>
        <FAQSParent>
          {FAQ_FAMIY.map((item, index) => {
            const isSelected = index === openFAQ;
            return (
              <FAQContainer isSelected={isSelected} key={index}>
                <div
                  className="question-wrapper"
                  onClick={() => setOpenFAQ(isSelected ? null : index)}
                >
                  <button className="icon">{isSelected ? '-' : '+'}</button>
                  <h4 style={{ fontFamily: 'Roboto' }} className="question-text">
                    {item.question}
                  </h4>
                </div>
                {isSelected && (
                  <p style={{ fontFamily: 'Roboto' }} className="answer">
                    {item.answer}
                  </p>
                )}
              </FAQContainer>
            );
          })}
        </FAQSParent>
        <TutorFAQSHead>
          <h3 className="Tutor-FAQ-text">Tutor FAQ'S </h3>
        </TutorFAQSHead>
        <FAQSParent>
          {FAQ_TUTOR.map((item, index) => {
            const isSelected = index === openFAQTutor;
            return (
              <FAQContainer isSelected={isSelected} key={index}>
                <div
                  className="question-wrapper"
                  onClick={() => setOpenFAQTutor(isSelected ? null : index)}
                >
                  <button className="icon">{isSelected ? '-' : '+'}</button>
                  <h4 style={{ fontFamily: 'Roboto' }} className="question-text">
                    {item.question}
                  </h4>
                </div>
                {isSelected && (
                  <p style={{ fontFamily: 'Roboto' }} className="answer">
                    {item.answer}
                  </p>
                )}
              </FAQContainer>
            );
          })}
        </FAQSParent>
        {/* <DownAppHead>
          <h3 className="download-text">Download App</h3>
        </DownAppHead>
        <DownParent>
          <DownLeft>
            <p className="heading-left">
              Duis aute irure dolor in reprehenderit in voluptate <br />
              velit
              <hr style={{ 'border-bottom': '2px solid #000', opacity: '1' }} />
            </p>
            <p style={{ fontFamily: 'Roboto' }} className="left-para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
            <div className="btn">
              <button className="google-btn-download">
                <img alt="" src="./static/img/google-btn.png" style={{ width: '150px' }} />
              </button>
              <button className="mac-btn-download">
                <img alt="" src="./static/img/mac-btn.png" style={{ width: '150px' }} />
              </button>
            </div>
          </DownLeft>

          <DownRight>
            <div className="bg-iphone"></div>
            <img alt="" src="./static/img/iphone-image.png" className="iphone-image" />
          </DownRight>
        </DownParent> */}
        <Footer>
          <div className="background-input">
            <div className="div-contact">
              <p className="contact-us">Let’s Stay Connected</p>
            </div>
            <div className="div-para">
              <p style={{ fontFamily: 'Roboto' }} className="input-para">
                If you have questions or special inquires, please fill out this form and we will get
                back to you as quickly as possible
              </p>
            </div>
            <div className="input-div">
              <div className="first-two-input">
                <div className="box-1">
                  <label style={{ fontFamily: 'Roboto' }} htmlFor="phone">
                    Phone Number
                  </label>
                  <br />
                  <input type="number" name="phone" className="mobile-one" />
                </div>
                <div className="box-2">
                  <label style={{ fontFamily: 'Roboto' }} htmlFor="email">
                    Email
                  </label>
                  <br />
                  <input type="email" name="email" className="mobile-two" />
                </div>
              </div>
              <div className="div-mobile">
                <label style={{ fontFamily: 'Roboto' }} htmlFor="message">
                  Message
                </label>
                <br />
                <textarea type="text" name="message" className="mobile-three"></textarea>
              </div>
              <div className="check-agree">
                <input type="checkbox" name="checkbox" />
                <p style={{ fontFamily: 'Roboto' }} className="agree">
                  I agree to the processing of the personal data provided
                </p>
              </div>
              <button style={{ fontFamily: 'Roboto' }} type="submit" className="submit-btn">
                SUBMIT
              </button>
            </div>
          </div>
        </Footer>

        <Links>
          {/* <button className="watch-btn">Watch now</button>
              <button className="view-btn">View All </button> */}
          <a target="_" href="https://www.instagram.com/teachersonlytutoring" className="a-btn-download">
            <img
              alt=""
              src="./static/img/instagram-btn.png"
              style={{ width: 'inherit', height: 'inherit', objectFit: 'contain' }}
            />
          </a>
          <a target="_" href="https://www.instagram.com/teachersonlytutoring" className="a-btn-download">
            <img
              alt=""
              src="./static/img/facebook-btn.jpg"
              style={{ width: 'inherit', height: 'inherit', objectFit: 'contain' }}
            />
          </a>
        </Links>
      </Wrapper>
    </>
  );
};
export default Home;
