import ApproveIcon from '../assets/images/icons/approve-icon.svg';
import FeeIcon from '../assets/images/icons/fee-icon.svg';
import FeebackIcon from '../assets/images/icons/feedback-icon.svg';
import ListOfAllIcon from '../assets/images/icons/list-of-all-icon.svg';
import ReportIcon from '../assets/images/icons/report-icon.svg';
import UploadIcon from '../assets/images/icons/upload-icon.svg';

export const sidebarData = [
  {
    name: 'list_of_all',
    icon: ListOfAllIcon,
    path: '/admin',
    title: 'All Users',
  },
  {
    name: 'approve',
    icon: ApproveIcon,
    path: '/admin/approved-accounts',
    title: 'All Requests',
  },
  {
    name: 'feeback',
    icon: FeebackIcon,
    path: '/admin/feedback',
    title: 'Feebacks',
  },
  {
    name: 'upload',
    icon: UploadIcon,
    path: '/admin/upload',
    title: 'Upload',
  },
  {
    name: 'report',
    icon: ReportIcon,
    path: '/admin/report',
    title: 'Reports',
  },
  {
    name: 'fee',
    icon: FeeIcon,
    path: '/admin/fees',
    title: 'Fees',
  },
];
