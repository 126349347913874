import React, { useState } from 'react';
import NormalButton from '../buttons/NormalButton';
import {
  ModalContentWrapper,
  ModalTitle,
  ModalWrapper,
  UserAvatar,
  UserInfoItem,
  UserInfoTitle,
  UserInfoValue,
  UserInfoWrapper,
  UserName,
  UserNameAvatar,
} from './ProfileModal.styled';

const ProfileModal = ({ data, show, onClose }) => {
  return (
    <ModalWrapper show={show} onHide={onClose} centered>
      <ModalContentWrapper className="d-flex align-items-center flex-column">
        <ModalTitle className="text-center">User Profile</ModalTitle>
        {data.avatar ? (
          <UserAvatar src={data.avatar} />
        ) : (
          <UserNameAvatar backgroundColor={!data.name ? '#000' : ''}>
            {data.name ? data.name[0] + data.name[1] : ''}
          </UserNameAvatar>
        )}
        <UserName>{data.name}</UserName>
        <UserInfoWrapper>
          <UserInfoItem>
            <UserInfoTitle>Full Name</UserInfoTitle>
            <UserInfoValue>{data.name}</UserInfoValue>
          </UserInfoItem>
          <UserInfoItem>
            <UserInfoTitle>Phone Number</UserInfoTitle>
            <UserInfoValue>{data.phnoe}</UserInfoValue>
          </UserInfoItem>
          <UserInfoItem>
            <UserInfoTitle>Rating</UserInfoTitle>
            <UserInfoValue>{data.rating}</UserInfoValue>
          </UserInfoItem>
          {data.tutor ? (
            <>
              <UserInfoItem>
                <UserInfoTitle>University</UserInfoTitle>
                <UserInfoValue>{data.tutor.university}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoTitle>Experience</UserInfoTitle>
                <UserInfoValue>{data.tutor.years_of_experience}</UserInfoValue>
              </UserInfoItem>
            </>
          ) : (
            ''
          )}
        </UserInfoWrapper>
        <UserInfoWrapper padding="20px">
          <UserInfoTitle marginBottom="10px">Description</UserInfoTitle>
          <UserInfoValue>
            {data.tutor ? data.tutor.bio : data.student ? data.student.description : ''}
          </UserInfoValue>
        </UserInfoWrapper>
        <NormalButton content="Close" bordered onClick={onClose} />
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export default ProfileModal;
