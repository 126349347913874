import styled from 'styled-components';

export const ApprovedAccountsPageContainer = styled.div`
  max-width: 1188px;
  width: 100%;
`;

export const TopContainer = styled.div`
  margin-bottom: 42px;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 18px;
`;

export const ButtonIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 16px;
`;

export const UserContainer = styled.div`
  margin-top: 24px;
`;

export const PaginationWrapper = styled.div``;
