import styled from 'styled-components';

export const Popupwrapper = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99999999999;
  .box {
    position: relative;
    width: 80%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
    align-text: center;
  }
`;
