import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserAvatar from '../assets/images/avatars/avatar(1).png';
import { sidebarData } from '../config/sidebar.config';
import {
  Avatar,
  SidebarBtn,
  SidebarBtnIcon,
  SidebarBtnText,
  SidebarContainer,
  SidebarWrapper,
} from './Sidebar.styled';

const Sidebar = () => {
  const [activeBtn, setActiveBtn] = useState(sidebarData[0].name);
  const location = useLocation();

  useEffect(() => {
    const activeItem = sidebarData.find((item) => item.path.includes(location.pathname));
    if (activeItem) {
      setActiveBtn(activeItem.name);
    }
  }, [location.pathname]);

  return (
    <SidebarWrapper>
      <SidebarContainer className="text-center">
        <Avatar src={UserAvatar} alt="user-avatar" />
        {sidebarData.map((item, index) => (
          <SidebarBtn key={index} bg={activeBtn === item.name ? '#303030' : ''} to={item.path}>
            <SidebarBtnIcon img src={item.icon} alt={item.name} />
            <SidebarBtnText>{item.title}</SidebarBtnText>
          </SidebarBtn>
        ))}
      </SidebarContainer>
    </SidebarWrapper>
  );
};

export default Sidebar;
