/* eslint-disable prettier/prettier */
import AboutUs from './about-us';
import ContactUs from './contact-us';
import Home from './home';
import PrivacyPolicy from './privacy-policy';
import TermsConditions from './terms-and-conditions';

const pages = {
  home: Home,
  'about-us': AboutUs,
  'contact-us': ContactUs,
  'terms-conditions': TermsConditions,
  'privacy-policy': PrivacyPolicy,
};

export default pages;
