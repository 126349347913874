import styled from 'styled-components';

export const Container = styled.div`
  width: 1100px;
  margin: 0 auto;
  height: 100vh;
  overflow: scroll;
  font-family: 'Caveat';
`;

export const Header = styled.div`
  width: 100%;
  font-family: 'Caveat';
  margin: 50px 20px;

  .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #010101;
    margin-bottom: 15px;
  }

  .date {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #666666;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .box {
    width: 350px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 20px;
    font-family: 'Caveat';
    padding: 10px;
  }

  .title-text {
    font-size: 18px;
    color: #010101;
  }

  .description-text {
    font-size: 18px;
    color: #666666;
  }
`;
