import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

export const DropdownWrapper = styled(Dropdown)``;

export const DropdownButton = styled(Dropdown.Toggle)`
  padding: 18px 16px;
  border: 1.5px solid #000000 !important;
  border-radius: 5px;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: none !important;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  height: 56px;
  font-weight: 700;
  margin-bottom: 48px;
  &.filter-dropdown {
    width: 173px;
    margin-bottom: 0;
    margin-left: 17px;
  }
  &.status-dropdown {
    width: 94px;
    height: 36px;
    margin-bottom: 0;
    &.dark-mode {
      background-color: #000000 !important;
      color: #ffffff !important;
    }
  }
  &::after {
    display: none;
  }
`;

export const DropdownIcon = styled.img``;

export const DropdownMenu = styled(Dropdown.Menu)`
  transform: translate(0, 68px) !important;
  width: 100%;
  background: #ffffff;
  border: 1.5px solid #000000;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  &.status-dropdown-menu {
    transform: translate(0, 41px) !important;
    max-width: 94px;
    min-width: 94px;
    left: 0 !important;
    right: 0 !important;
  }
`;

export const DropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => props.height || '52px'};
  font-size: 16px;
  line-height: 20px;
  background-color: #fff;
  color: #000000;
  &:not(:last-child) {
    border-bottom: 1px solid #000000;
  }
  &:hover {
    color: #fff;
    background-color: #000000;
  }
`;
