import styled from 'styled-components';

export const SideBar = styled.div`
  justify-content: center;
  align-items: center;

  .img-logo {
    width: 100%;
  }

  .title {
    font-size: 100%;
    text-align: center;
    font-family: 'Caveat', cursive;
    color: #000;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    color: #000;

    span {
      padding: 20px;
      font-family: 'Caveat', cursive;
    }
  }
`;
