import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .modal-dialog {
    max-width: 580px;
    width: 100%;
    margin: auto;
    height: 100vh;
    padding: 20px;
    .modal-content {
      position: relative;
      border-radius: 0;
    }
  }
`;

export const ModalContentWrapper = styled(Modal.Body)`
  padding: 50px 80px;
`;

export const ModalTitle = styled.h2`
  font-size: 30px;
  line-height: 44px;
  margin-bottom: 40px;
`;

export const UserAvatar = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid #010101;
  object-fit: cover;
  margin-bottom: 15px;
`;

export const UserNameAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-bottom: 15px;
  font-size: 36px;
  text-transform: uppercase;
  color: #fff;
  background-color: ${(props) => props.backgroundColor || '#f06292'};
  padding-right: 5px;
`;

export const UserName = styled.h3`
  font-size: 25px;
  line-height: 44px;
  margin-bottom: 28px;
`;

export const UserInfoWrapper = styled.div`
  padding: ${(props) => props.padding || '5px 20px'};
  border: 1px solid #010101;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 16px;
`;

export const UserInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  line-height: 25px;
  padding: 7px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #d1d1d1;
  }
`;

export const UserInfoTitle = styled.p`
  color: #010101;
  font-size: ${(props) => props.fontSize || '20px'};
  margin-bottom: ${(props) => props.marginBottom};
`;

export const UserInfoValue = styled.p`
  color: #666666;
  font-size: ${(props) => props.fontSize || '20px'};
`;
