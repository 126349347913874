import React, { useEffect, useState } from 'react';
import { main_api } from 'adminPanel/api/auth';
import Pagination from 'adminPanel/components/Pagination';
import NormalButton from '../components/buttons/NormalButton';
import SearchInput from '../components/inputs/SearchInput';
import ReportTable from '../components/tables/ReportTable';
import { tableColumns } from '../config/report.config';
import { BtnPanel, ReportPageContainer } from './ReportPage.styled';

const ReportPage = () => {
  const [students, setStudents] = useState([]);
  const [originalStudentData, setOriginalStudentData] = useState([]);
  const [pageShowUsers, setPageShowUsers] = useState(10);
  const [activePaginationItem, setActivePaginationItem] = useState(1);

  const getAllStudents = async () => {
    const { data } = await main_api.get('/admin/users/?type=Student');
    setOriginalStudentData(data);
  };

  const displayUsers = () => {
    setStudents([
      ...originalStudentData.slice(
        pageShowUsers * (activePaginationItem - 1),
        pageShowUsers * activePaginationItem
      ),
    ]);
  };
  useEffect(() => {
    displayUsers();
  }, [activePaginationItem, originalStudentData]);

  useEffect(() => {
    getAllStudents();
  }, []);

  return (
    <ReportPageContainer>
      <SearchInput maxWidth="920px" />
      <BtnPanel className="d-flex">
        <NormalButton content="Weekly" width="160px" marginRight="16px" bordered />
        <NormalButton content="Monthly" width="160px" />
      </BtnPanel>
      <ReportTable data={students} columns={tableColumns} />
      <Pagination
        paginationNumber={Math.ceil(originalStudentData.length / pageShowUsers)}
        activePaginationItem={activePaginationItem}
        setActivePaginationItem={setActivePaginationItem}
        pageShowUsers={pageShowUsers}
        maxShowUsers={originalStudentData.length}
      />
    </ReportPageContainer>
  );
};

export default ReportPage;
