import React, { Children } from 'react';
import { NormalBtn } from './Button.styled';

const NormalButton = ({
  onClick,
  type,
  content,
  marginBottom,
  bordered,
  width,
  height,
  fontSize,
  marginRight,
  marginTop,
  padding,
}) => {
  return (
    <NormalBtn
      className={'d-flex align-items-center justify-content-center ' + (bordered ? 'bordered' : '')}
      type={type || 'button'}
      onClick={onClick}
      marginBottom={marginBottom}
      marginTop={marginTop}
      padding={padding}
      marginRight={marginRight}
      width={width}
      height={height}
      fontSize={fontSize}
    >
      {content}
    </NormalBtn>
  );
};

export default NormalButton;
