import styled, { css } from 'styled-components';

const easeOutQuart = 'cubic-bezier(0.25, 1, 0.5, 1)';

const fade = () => css`
  opacity: 0;

  &.appear-active,
  &.appear-done,
  &.enter-active,
  &.enter-done {
    opacity: 1;
    transition: opacity 500ms;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
`;

const translateX = (x) => css`
  transform: translateX(${x});

  &.appear-active,
  &.appear-done,
  &.enter-active,
  &.enter-done {
    transform: none;
    transition: transform 500ms ${easeOutQuart};
  }

  &.exit {
    transform: none;
  }

  &.exit-active {
    transform: translateX(${x});
    transition: transform 500ms ${easeOutQuart};
  }
`;

const translateY = (y) => css`
  transform: translateY(${y});

  &.appear-active,
  &.appear-done,
  &.enter-active,
  &.enter-done {
    transform: none;
    transition: transform 500ms ${easeOutQuart};
  }

  &.exit {
    transform: none;
  }

  &.exit-active {
    transform: translateY(${y});
    transition: transform 500ms ${easeOutQuart};
  }
`;

export const animations = {
  fade: fade(),
  bottom: translateY('100%'),
  top: translateY('-100%'),
  right: translateX('100%'),
  left: translateX('-100%'),
};

export const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
`;

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  ${(props) => animations[props.animation]}
`;

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  right: 8px;
  width: 36px;
  height: 36px;
  padding: 12px;
  color: #fff;

  svg {
    width: 100%;
  }
`;
